import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router';
import * as storeActions from '../../store/store/actions';
import MainContainer from '../MainContainer';
import * as storeSelectors from '../../store/store/reducer';
import * as legendSelectors from '../../store/legend/reducer';
import * as legendActions from '../../store/legend/actions';
import StoreMap from '../../components/StoreMap';
import Error from '../../components/Error';

class StoreContainer extends Component {

  componentDidMount() {
    const {
      match,
      dispatch,
    } = this.props;

    if (match && match.params) {
      if (match.params.storeId) {
        dispatch(storeActions.setFetching(true));
        dispatch(storeActions.loadStore(match.params.storeId)).then(() => {
          dispatch(storeActions.setFetching(false));
        });
        dispatch(legendActions.setFetching(true));
        dispatch(legendActions.loadLegend());
      }
      if (match.params.currentDisplayId) {
        this.currentDisplayId = match.params.currentDisplayId;
      }
    }
  }

  renderContent = () => {
    if (this.props.fetchingStore || this.props.fetchingLegend) {
      return <CircularProgress />;
    }
    if (this.props.error !== null) {
      return (
        <Error
          code={this.props.error.code}
          message={this.props.error.message}
        />
      );
    }

    return <StoreMap currentDisplayId={this.currentDisplayId} />;
  };

  render() {
    return (
      <MainContainer>
        { this.renderContent() }
      </MainContainer>
    );
  }
  
}

function mapStateToProps(state) {
  return {
    fetchingStore: storeSelectors.getFetching(state),
    error: storeSelectors.getErrorIfExist(state),
    fetchingLegend: legendSelectors.getFetching(state),
  };
}

export default connect(mapStateToProps)(withRouter(StoreContainer));
