/* global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as THREE from 'three';
import OrbitControls from 'three-orbitcontrols';
// import { detailedDiff } from 'deep-object-diff';
import { MTLLoader, OBJLoader } from 'three-obj-mtl-loader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { withRouter } from 'react-router';
import $ from 'jquery';
import * as shelfSelectors from '../../store/shelf/reducer';
import ProductInfo from '../ProductInfo';
import ShelfInfo from '../ShelfInfo';
import config from '../../config';
import BasketDialog from '../BasketDialog';
import * as shelfActions from '../../store/shelf/actions';
import Toolbar from '../Toolbar';
import Search from '../Search';
/* import * as storeSelectors from '../../store/store/reducer'; */
import Loader from '../Loader';
// import { Box } from '@material-ui/core';
import Zoomer from '../Zoomer';
import { rotationByAngle, getCenterPositionByAngle } from '../../helpers/helper3d';
import Error from '../Error';

// const FLOOR_PLANE_COLOR = 0xAfafaf;
// const SHELF_WALLS_COLOR = 0x5f5e5e;

const CLICK_THRESHOLD = 5;
const TOUCH_THRESHOLD = 3;

class ShelfView3D extends Component {
  constructor(props) {
    super(props);
    this.mouse = null;
    this.raycaster = null;
    this.renderer = null;
    this.scene = null;
    this.camera = null;
    this.orbitControlsShelf = null;
    this.INTERSECTED = null;
    this.selectObject = null;
    this.offsetX = null;
    this.initialPosition = null;

    this.state = {
      productInfoIsOpen: false,
      selectedProductInfo: null,
      selectedShelfInfo: null,
      basketIsOpen: false,
      isLoading: false,
      isFetching: true,
      progressItemsLoaded: 0,
      progressItemsTotal: 1,
    };

    this.animate = this.animate.bind(this);
    this.onDocumentMouseMove = this.onDocumentMouseMove.bind(this);
    this.onDocumentMouseDown = this.onDocumentMouseDown.bind(this);
    this.onDocumentMouseUp = this.onDocumentMouseUp.bind(this);
    this.onDocumentMouseMove = this.onDocumentMouseMove.bind(this);
    this.onDocumentMouseClick = this.onDocumentMouseClick.bind(this);
    this.onDocumentTouch = this.onDocumentTouch.bind(this);
    this.onDocumentTouchEnd = this.onDocumentTouchEnd.bind(this);
    this.openBasket = this.openBasket.bind(this);
    this.closeBasket = this.closeBasket.bind(this);
    this.showFoundedProduct = this.showFoundedProduct.bind(this);
    this.removeSelectFromFoundedProducts = this.removeSelectFromFoundedProducts.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      match,
    } = this.props;

    if (match.params && match.params.shelfId) {
      dispatch(shelfActions.setFetching(true));
      dispatch(shelfActions.loadProductsOnShelf(match.params.shelfId)).then(() => {
        dispatch(shelfActions.setFetching(false));
      });
    }

    window.onresize = () => {
      this.renderer.setSize(
        document.getElementById('3d-shelf-view-container').clientWidth,
        document.getElementById('3d-shelf-view-container').clientHeight,
      );
    };

    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillReceiveProps(nextProps) {
    const { shelfInfo, productsList, neighbours } = nextProps;
    // const { shelfInfo: oldShelfInfo, productsList: oldProductsList } = this.props;
    // const changedShelfInfo = detailedDiff(oldShelfInfo, shelfInfo);
    // const changedProductsList = detailedDiff(oldProductsList, productsList);
    if (
      shelfInfo
      && productsList // todo uncomment for better performance
    /*      && (
        (changedShelfInfo.added && Object.keys(changedShelfInfo.added).length)
      || (changedShelfInfo.updated && Object.keys(changedShelfInfo.updated).length)
      || (changedShelfInfo.deleted && Object.keys(changedShelfInfo.deleted).length)
      || (changedProductsList.added && Object.keys(changedProductsList.added).length)
      || (changedProductsList.updated && Object.keys(changedProductsList.updated).length)
      || (changedProductsList.deleted && Object.keys(changedProductsList.deleted).length)
      ) */) {
      this.prepareScene(shelfInfo, productsList, neighbours);
    }
  }

  componentWillUnmount() {
    this.mouse = null;
    this.raycaster = null;
    this.renderer = null;
    this.scene = null;
    this.camera = null;
    this.orbitControlsShelf.enabled = false;
    this.orbitControlsShelf = null;
    window.removeEventListener('resize', this.onWindowResize);
  }

  onDocumentMouseDown(event) {
    this.mouseDown = true;
    this.moveStartPosition = { x: event.clientX, y: event.clientY };
  }

  onDocumentMouseUp() {
    this.mouseDown = false;
  }

  onDocumentMouseMove(event) {
    event.preventDefault();
    this.mouse.x = ((event.clientX - this.renderer.domElement.offsetLeft)
      / this.renderer.domElement.width) * 2 - 1;
    this.mouse.y = -((event.clientY - this.renderer.domElement.offsetTop)
      / this.renderer.domElement.height) * 2 + 1;

    const { windowMode } = this.props;
    if (windowMode) {
      this.mouse.y += 0.18;
    }
  }

  onDocumentMouseClick(event) {
    this.moveEndPosition = { x: event.clientX, y: event.clientY };
    const diff = this.calcMaxDistance(this.moveStartPosition, this.moveEndPosition);
    if (diff > CLICK_THRESHOLD) return;

    if (
      this.INTERSECTED
      && (!this.selectObject || this.selectObject.uuid !== this.INTERSECTED.uuid)
    ) {
      const objContainsChildWithId = (object, id) => {
        if (object.uuid === id) return true;
        return object.children.filter(o => objContainsChildWithId(o, id)).length > 0;
      };
      const self = this;
      const objectsForInteract = this.getObjectsForIntersect();
      objectsForInteract.forEach((object) => {
        const uuidIsEquil = objContainsChildWithId(object, self.INTERSECTED.uuid);
        if (uuidIsEquil) {
          self.orbitControlsShelf.enablePan = false;
          if (object.isNeighbor) {
            self.setState({
              productInfoIsOpen: false,
              selectedProductInfo: null,
              selectedShelfInfo: object.data,
            });
          } else {
            self.setState({
              selectedProductInfo: object.isChild ? object.parent.productInfo : object.productInfo,
              productInfoIsOpen: true,
              selectedShelfInfo: null,
            });
          }
        }
        if (!uuidIsEquil && object.firstColor) {
          if (object.material) {
            if (object.material[0]) {
              object.material.forEach(material => material.color.setHex(String(object.firstColor)));
            } else {
              object.material.color.setHex(String(object.firstColor));
            }
          }
        }
      });

      this.selectObject = this.INTERSECTED;
    }
  }

  onDocumentTouch(event) {
    event.preventDefault();
    const x = event.touches[0].clientX;
    const y = event.touches[0].clientY;
    this.mouse.x = ((x - this.renderer.domElement.offsetLeft)
      / this.renderer.domElement.width) * 2 - 1;
    this.mouse.y = -((y - this.renderer.domElement.offsetTop)
      / this.renderer.domElement.height) * 2 + 1;
    this.moveStartPosition = { x, y };
  }

  onDocumentTouchEnd(event) {
    const x = event.changedTouches[0].clientX;
    const y = event.changedTouches[0].clientY;

    this.mouse.x = ((x - this.renderer.domElement.offsetLeft)
      / this.renderer.domElement.width) * 2 - 1;
    this.mouse.y = -((y - this.renderer.domElement.offsetTop)
      / this.renderer.domElement.height) * 2 + 1;

    this.moveEndPosition = { x, y };

    const diff = this.calcMaxDistance(this.moveStartPosition, this.moveEndPosition);
    if (diff > TOUCH_THRESHOLD) return;

    this.onDocumentMouseClick(event);
  }

  onWindowResize = () => {
    const sceneContainer = document.getElementById('3d-shelf-view-container');
    this.updateSceneSizeProportion(sceneContainer.clientWidth, sceneContainer.clientHeight);
  };

  setIsProductToChildren(item) {
    item.isProduct = true;
    item.isGltf = true;
    if (item.children) {
      item.children.forEach(child => this.setIsProductToChildren(child));
    }
  }

  getDepthOffset() {
    this.scene.updateMatrixWorld();
    const { shelfInfo } = this.props;
    const { width, height } = shelfInfo;
    const startPointCoords = {
      x: width / 10 / 2,
      y: height / 10 / 2,
    };
    const direction = new THREE.Vector3(0, 0, -100).normalize();
    const startPoint = new THREE.Vector3(startPointCoords.x, startPointCoords.y, 0);
    const ray = new THREE.Raycaster(startPoint, direction);
    const intersects = ray.intersectObjects(this.getWallForIntersect());
    if (intersects && intersects.length !== 0) {
      return -intersects[0].distance;
    }
    return 0;
  }

  getWallForIntersect() {
    const objects = [];
    this.scene.children.forEach((item) => {
      if (item.isWall) {
        if (item.children && item.children.length !== 0) {
          item.children.forEach((childItem) => {
            objects.push(childItem);
          });
        } else {
          objects.push(item);
        }
      }
    });
    return objects;
  }

  getObjectsForIntersect() {
    const objects = [];
    const addChildrenToObjects = (item) => {
      item.forEach((ch) => {
        objects.push(ch);
        if (ch.children && ch.children.length) {
          addChildrenToObjects(ch);
        }
      });
    };
    this.scene.children.forEach((item) => {
      if (item.isProduct || item.isNeighbor) {
        if (item.children && item.children.length !== 0 && item.type === 'Group') {
          addChildrenToObjects(item);
        } else {
          objects.push(item);
        }
      }
    });
    this.currentShelf.children.forEach((item) => {
      if (item.isProduct) objects.push(item);
    });
    return objects;
  }

  static getShelfPositionRelatedWithMainShelf(shelfInfo, mainShelfInfo) {
    const shelf = {
      ...shelfInfo,
      x: shelfInfo.pos_x,
      y: shelfInfo.pos_y,
      depth: shelfInfo.length,
    };
    const [sx, sy] = getCenterPositionByAngle(shelf);
    return {
      x: (sx - mainShelfInfo.pos_x) / 10,
      z: (sy - mainShelfInfo.pos_y) / 10, // 2d -> 3d
    };
  }

  getCamDistance = (maxSide, shelfHeight, camAngle) => {
    const correctionDistanceCoef = 55;
    const maxSideUnitToNormalUnitCoef = 1;
    const gradsToRadians = grads => grads * Math.PI / 180;
    const camDistance = (maxSideUnitToNormalUnitCoef * maxSide.shelf
      * Math.sin(gradsToRadians(75))) / Math.sin(gradsToRadians(camAngle / 2));
    return {
      distance: camDistance / maxSide.window * correctionDistanceCoef
    };
  };

  calcMaxDistance = (p1, p2) => Math.max(Math.abs(p1.x - p2.x), Math.abs(p1.y - p2.y));

  updateSceneSizeProportion = (width, height) => {
    if (this.renderer === null || this.camera === null) return;
    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  };

  goToShelf = () => {
    const { selectedShelfInfo } = this.state;
    if (selectedShelfInfo && selectedShelfInfo.shelfId) {
      const { history, match } = this.props;
      history.push(`/${match.params.storeId}/shelf-view/${selectedShelfInfo.shelfId}`);
    }
  };

  setHighlighting = (objects) => {
    const highlightIt = (color, object = this.INTERSECTED) => {
      if (!object) return;

      if (object.material[0]) {
        object.material.forEach(
          material => material.color.setHex(color),
        );
      } else {
        object.material.color.setHex(color);
      }
    };
    const currentObject = objects.length > 0 ? objects[0].object : null;
    if (!currentObject) {
      if (
        this.INTERSECTED
        && (!this.selectObject || this.selectObject.uuid !== this.INTERSECTED.uuid)
      ) {
        highlightIt(String(this.INTERSECTED.firstColor));
        this.INTERSECTED = null;
      }
      return;
    }
    if (
      this.INTERSECTED && this.INTERSECTED.uuid !== currentObject.uuid
      && (!this.selectObject || this.selectObject.uuid !== this.INTERSECTED.uuid)
    ) {
      highlightIt(String(this.INTERSECTED.firstColor));
    }
    const oldIntersectedId = this.INTERSECTED ? this.INTERSECTED.uuid : null;
    this.INTERSECTED = currentObject;
    if (!this.INTERSECTED.firstColor) {
      if (this.INTERSECTED.material[0]) {
        this.INTERSECTED.firstColor = this.INTERSECTED.material[0].color.getHex();
      } else {
        this.INTERSECTED.firstColor = this.INTERSECTED.material.color.getHex();
      }
    }
    if (this.INTERSECTED.material[0]) {
      this.INTERSECTED.previewColor = this.INTERSECTED.material[0].color.getHex();
    } else {
      this.INTERSECTED.previewColor = this.INTERSECTED.material.color.getHex();
    }
    if (
      oldIntersectedId !== currentObject.uuid
      && this.INTERSECTED
      && (!this.selectObject || this.selectObject !== this.INTERSECTED.uuid)
    ) {
      highlightIt(0xACC4C4);
    }
  };

  getIntersectedObjects = () => this.raycaster.intersectObjects(
    this.getObjectsForIntersect(),
    true,
  );

  findProduct = productName => new Promise((resolve) => {
    const trimedName = (productName || '').trim();
    if (trimedName.length < 2) {
      resolve([]);
      return;
    }

    const { productsList } = this.props;
    const regExp = new RegExp(`^.*${productName}.*`);
    const founded = productsList
      .filter(productItem => productItem.product
        && productItem.product.description1_de
        && regExp.test(productItem.product.description1_de))
      .map(productItem => ({ ...productItem, label: productItem.product.description1_de }));

    resolve(founded);
  });

  productAddToScene = (cube, product, depthOffset, shelf) => {
    if (cube.material) {
      if (cube.material[0]) {
        cube.firstColor = cube.material[0].color.getHex();
      } else if (cube.material) {
        cube.firstColor = cube.material.color.getHex();
      }
    }
    const { width, depth, height } = shelf.geometry.parameters;
    const posX = Number(product.pos_x.replace(',', '.')) / 10;
    const posY = Number(product.pos_y.replace(',', '.')) / 10;
    const productWidth = Number(product.product.width.replace(',', '.')) / 10;
    const productHeight = Number(product.product.height.replace(',', '.')) / 10;
    const productDepth = Number(product.product.depth.replace(',', '.')) / 10;
    cube.position.set(
      (posX + productWidth / 2) - (width / 2),
      (posY + productHeight / 2) - (height / 2),
      (productDepth / 2) + (depth / 2) + 0.2
    );
    cube.productId = product.product.id;
    cube.isProduct = true;
    cube.productInfo = product;
    cube.receiveShadow = true;
    cube.castShadow = true;
    shelf.add(cube);
  };

  onManagerStart = (url, itemsLoaded, itemsTotal) => {
    this.setState({
      isLoading: true,
      progressItemsLoaded: itemsLoaded,
      progressItemsTotal: itemsTotal,
    });
  };

  onManagerLoad = () => {
    console.log('Loading complete!');
    this.setState({
      isLoading: false,
    });
  };

  onManagerProgress = (url, itemsLoaded, itemsTotal) => {
    this.setState({
      progressItemsLoaded: itemsLoaded,
      progressItemsTotal: itemsTotal,
    });
  };

  onManagerError = (url) => {
    console.log(`Error loading file: ${url}`);
  };

  createCamera = (shelfInfo, cube) => {
    const fov = 30;
    this.camera = new THREE.PerspectiveCamera(
      fov,
      window.innerWidth / window.innerHeight,
      1,
      10000
    );
    this.orbitControlsShelf = new OrbitControls(this.camera, this.renderer.domElement);

    const maxSide = {};
    if ((shelfInfo.width / window.innerWidth) > (shelfInfo.height / window.innerHeight)) {
      maxSide.shelf = shelfInfo.width;
      maxSide.window = window.innerWidth;
    } else {
      maxSide.shelf = shelfInfo.height;
      maxSide.window = window.innerHeight;
    }
    const { distance: distanceToCamera } = this.getCamDistance(maxSide, shelfInfo.height, fov);
    const camHeight = (shelfInfo.height / 20); // shelfInfo.height / (2 * 8.6);
    const additionalCameraHeight = (1.2 * shelfInfo.height - 408) / 410;
    const additionalTargetHeight = (1.9 * shelfInfo.height - 186) / 460;

    const radius = distanceToCamera + (shelfInfo.depth / 20);

    const cameraPosition = { x: 0, y: camHeight + additionalCameraHeight, z: radius };
    this.initialPosition = cameraPosition;
    this.camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
    cube.add(this.camera);
    this.orbitControlsShelf.target.set(
      shelfInfo.width / 20,
      camHeight + additionalTargetHeight,
      shelfInfo.depth / 20,
    );
    this.orbitControlsShelf.maxPolarAngle = Math.PI / 1.9;
    this.orbitControlsShelf.minPolarAngle = Math.PI / 3;
    this.orbitControlsShelf.maxAzimuthAngle = Math.PI / 3;
    this.orbitControlsShelf.minAzimuthAngle = -Math.PI / 3;
    this.orbitControlsShelf.maxDistance = radius * 1.1;
    this.orbitControlsShelf.minDistance = radius / 5;
    const { orbitControlsShelf } = this;
    $('#shelf-view-search').focusin(() => {
      orbitControlsShelf.enablePan = false;
    }).focusout(() => {
      orbitControlsShelf.enablePan = true;
    });
  };

  closeInfo = () => {
    if (this.INTERSECTED && this.INTERSECTED.material) {
      if (this.INTERSECTED.material[0]) {
        this.INTERSECTED.material.forEach(material => material
          .color.setHex(String(this.INTERSECTED.firstColor)));
      } else {
        this.INTERSECTED.material.color.setHex(String(this.INTERSECTED.firstColor));
      }
    }
    this.selectObject = null;
    this.INTERSECTED = null;
    this.setState({
      selectedShelfInfo: null,
      productInfoIsOpen: false,
    });
  };

  create3DView(shelfInfo, productList, neighbours) {
    const getPlaneSizes = () => {
      const getSizeForPlaneXAndY = (angle, width, depth) => {
        const neighbourRotate = angle % 360;
        return {
          x: neighbourRotate === 0 || neighbourRotate === 180
            ? width / 2
            : depth / 2,
          y: neighbourRotate === 90 || neighbourRotate === 270
            ? width / 2
            : depth / 2
        };
      };
      const shelfSizeForPlanes = getSizeForPlaneXAndY(
        shelfInfo.angle,
        shelfInfo.width,
        shelfInfo.depth,
      );
      let maxX = Number(shelfInfo.pos_x) + shelfSizeForPlanes.x;
      let maxY = Number(shelfInfo.pos_y) + shelfSizeForPlanes.y;
      let minX = Number(shelfInfo.pos_x) - shelfSizeForPlanes.x;
      let minY = Number(shelfInfo.pos_y) - shelfSizeForPlanes.y;
      neighbours.forEach((neighbour) => {
        const { angle, width, length: depth } = neighbour;
        const neighbourSizeForPlanes = getSizeForPlaneXAndY(angle, width, depth);
        const posX = Number(neighbour.pos_x);
        const posY = Number(neighbour.pos_y);
        if (posX + neighbourSizeForPlanes.x > maxX) maxX = posX + neighbourSizeForPlanes.x;
        if (posY + neighbourSizeForPlanes.y > maxY) maxY = posY + neighbourSizeForPlanes.y;
        if (posX - neighbourSizeForPlanes.x < minX) minX = posX - neighbourSizeForPlanes.x;
        if (posY - neighbourSizeForPlanes.y < minY) minY = posY - neighbourSizeForPlanes.y;
      });
      const sizeX = maxX - minX;
      const sizeY = maxY - minY;
      const offsetX = (minX - shelfInfo.pos_x + sizeX / 2) / 10;
      const offsetY = (minY - shelfInfo.pos_y + sizeY / 2) / 10;
      const additionalY = Number.isFinite(this.orbitControlsShelf.maxDistance)
        ? this.orbitControlsShelf.maxDistance
        : 0;
      return {
        additionalY,
        x: sizeX,
        y: sizeY,
        offsetY: offsetY + (additionalY / 6),
        offsetX,
      };
    };
    this.createScene(shelfInfo);
    this.createShelf(shelfInfo).then((cube) => {
      this.currentShelf = cube;
      this.createCamera(shelfInfo, cube);
      const planeSizes = getPlaneSizes();
      this.addPlane(
        planeSizes.x / 5,
        (planeSizes.y / 5) + planeSizes.additionalY / 3,
        planeSizes.offsetX,
        planeSizes.offsetY,
      ); // this.orbitControlsShelf.maxDistance
      cube.rotation.y = Math.floor(shelfInfo.angle / 90) * Math.PI / 2;
      // cube.rotateOnAxis(new THREE.Vector3(0, 1, 0), 3 * shelfInfo.angle * Math.PI / 180);
      this.orbitControlsShelf.update();
      this.animate();
      this.createObjects(productList, cube);
      this.showProductAfterFindingOnMap();

      if (neighbours) {
        this.createNeighboursShelves(neighbours, shelfInfo);
      }

      this.setState({ isFetching: false });
    });
    this.addLight();
  }

  createObjects(productsList, shelf) {
    const depthOffset = this.getDepthOffset();
    if (productsList && productsList.length !== 0) {
      productsList.forEach((product) => {
        let cube = null;

        const depth = product.product.depth && product.product.depth !== 'null'
          ? product.product.depth.replace(',', '.')
          : 10;
        if (product.product.model) {
          if (product.product.model.gltf) {
            const loader = new GLTFLoader(this.loadingManager);
            // loader.setPath(product.product.model.path);
            loader.load(
              product.product.model.gltf,
              (gltf) => {
                // called when the resource is loaded

                const object = gltf.scene;
                object.scale.set(1, 1, 1);
                if (object.children) {
                  this.setIsProductToChildren(object);
                  for (const child of object.children) {
                    child.isChild = true;
                    if (child.material) {
                      child.firstColor = child.material.color.getHex();
                    }
                    child.productId = product.product.id;
                    child.productInfo = product;
                  }
                  object.isParent = true;
                  object.isGltf = true;
                }
                this.productAddToScene(object, product, depthOffset, shelf);
              },
              (xhr) => {
                // called while loading is progressing
                console.log(`${(xhr.loaded / xhr.total * 100)}% loaded`);
              },
              (error) => {
                // called when loading has errors
                console.error('An error happened', error);
              },
            );
          } else {
            const mtlLoader = new MTLLoader(this.loadingManager);

            const objLoader = new OBJLoader(this.loadingManager);
            mtlLoader.setPath(product.product.model.path);
            mtlLoader.load(product.product.model.mtl, (materials) => {
              materials.preload();
              objLoader.setMaterials(materials);
              objLoader.load(product.product.model.obj, (object) => {
                object.scale.set(0.01, 0.01, 0.01);
                if (object.children) {
                  for (const child of object.children) {
                    child.isChild = true;
                    child.firstColor = child.material.color.getHex();
                    child.productId = product.product.id;
                    child.isProduct = true;
                    child.productInfo = product;
                  }
                }
                this.productAddToScene({
                  ...object,
                  isParent: object.children,
                }, product, depthOffset, shelf);
              });
            });
          }
        } else if (product.product.image) {
          const cubeGeometry = new THREE.BoxGeometry(
            Number(product.product.width.replace(',', '.') / 10),
            Number(product.product.height.replace(',', '.') / 10),
            Number(depth / 10),
          );
          const loader = new THREE.TextureLoader(this.loadingManager);
          const materialArray = [
            new THREE.MeshBasicMaterial({
              map: loader.load(config.apiUrl + product.product.image),
            }),
            new THREE.MeshBasicMaterial({
              map: loader.load(config.apiUrl + product.product.image),
            }),
            new THREE.MeshBasicMaterial({
              map: loader.load(config.apiUrl + product.product.image),
            }),
            new THREE.MeshBasicMaterial({
              map: loader.load(config.apiUrl + product.product.image),
            }),
            new THREE.MeshBasicMaterial({
              map: loader.load(config.apiUrl + product.product.image),
            }),
            new THREE.MeshBasicMaterial({
              map: loader.load(config.apiUrl + product.product.image),
            }),
          ];
          cube = new THREE.Mesh(cubeGeometry, materialArray);
          this.productAddToScene(cube, product, depthOffset, shelf);
        } else {
          const geometry = new THREE.CubeGeometry(
            Number(product.product.width.replace(',', '.') / 10),
            Number(product.product.height.replace(',', '.') / 10),
            Number(depth / 10),
          );
          const material = new THREE.MeshBasicMaterial();
          cube = new THREE.Mesh(geometry, material);
          cube.material.color.setHex('0xA9a9a9');
          this.productAddToScene(cube, product, depthOffset, shelf);
        }
      });
    }
  }

  createScene() {
    this.loadingManager = new THREE.LoadingManager(
      this.onManagerLoad,
      this.onManagerProgress,
      this.onManagerError
    );
    this.loadingManager.onStart = this.onManagerStart;

    const sceneContainer = document.getElementById('3d-shelf-view-container');
    this.mouse = new THREE.Vector2();
    this.raycaster = new THREE.Raycaster(); // ,this.INTERSECTED;//eslint-disable-line
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setClearColor(0xa3a3a3, 1);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.domElement.id = 'shelf-view-canvas';

    this.renderer.setSize(sceneContainer.clientWidth, sceneContainer.clientHeight);
    sceneContainer.append(this.renderer.domElement);

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xcff4f9);
  }

  addPlane(maxDistanceForX, maxDistanceForY, offsetX = 0, offsetY = 0) {
    const DEFAULT_SIZE = 512;
    const REPEAT_PLANE_COEF = 21;
    const planeWidth = maxDistanceForX || DEFAULT_SIZE;
    const planeHeight = maxDistanceForY || DEFAULT_SIZE;
    const geometry = new THREE.PlaneGeometry(
      planeWidth, planeHeight, 0, 0
    );
    const texture = new THREE.TextureLoader(this.loadingManager).load('/images/plane.png');
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(
      Math.ceil(planeWidth / REPEAT_PLANE_COEF),
      Math.ceil(planeHeight / REPEAT_PLANE_COEF),
    );
    const material = new THREE.MeshPhongMaterial({
      // color: FLOOR_PLANE_COLOR,
      map: texture,
      side: THREE.BackSide,
      transparent: true,
    });
    const plane = new THREE.Mesh(geometry, material);
    plane.receiveShadow = true;
    plane.castShadow = false;
    plane.isMainPlane = true;
    plane.rotateX(Math.PI / 2);
    if (this.offsetX !== null) {
      plane.position.set(this.offsetX + offsetX, 0, offsetY);
    } else {
      plane.position.set(offsetX, 0, offsetY);
    }
    this.scene.add(plane);
  }

  createRightWall() {
    const { shelfInfo } = this.props;
    const geometry = new THREE.CubeGeometry(0.1, shelfInfo.height / 10, shelfInfo.depth / 10);
    const material = new THREE.MeshStandardMaterial({
      roughness: 0.5,
      metalness: 0.6,
      emissive: 0x0,
      color: 0xb8b8b8,
    });
    const cube = new THREE.Mesh(geometry, material);
    // cube.material.color.setHex(SHELF_WALLS_COLOR);
    cube.position.set(0, shelfInfo.height / 10 / 2, 0);
    cube.receiveShadow = true;
    cube.castShadow = true;
    this.scene.add(cube);
  }

  createLeftWall() {
    const { shelfInfo } = this.props;
    const geometry = new THREE.CubeGeometry(0.1, shelfInfo.height / 10, shelfInfo.depth / 10);
    const material = new THREE.MeshStandardMaterial({
      roughness: 0.5,
      metalness: 0.6,
      emissive: 0x0,
      color: 0xb8b8b8,
    });
    const cube = new THREE.Mesh(geometry, material);
    // cube.material.color.setHex(SHELF_WALLS_COLOR);
    cube.position.set(shelfInfo.width / 10, shelfInfo.height / 10 / 2, 0);
    cube.receiveShadow = true;
    cube.castShadow = true;
    this.scene.add(cube);
  }

  createBackWall(shelfInfo) {
    const {
      height, width, depth, angle,
    } = shelfInfo;

    const object = {
      height, width, depth, angle, x: 0, y: 0
    };
    const [cx, cz] = getCenterPositionByAngle(object);
    const cy = height / 2;

    return this.createBox(
      height / 10,
      depth / 10,
      width / 10,
      cx / 10,
      cy / 10,
      cz / 10,
      angle,
      null,
      {
        shelfId: shelfInfo.id,
        shelfType: shelfInfo.shelf_type ? shelfInfo.shelf_type.shelve_type : '',
        gangNumber: shelfInfo.gang_number,
        name: shelfInfo.shelf_name,
        productsExisting: !!shelfInfo.planogram,
      }
    );
  }

  createShelfModel(shelfInfo, mainShelfInfo) {
    const { shelf_type: shelfType } = shelfInfo;
    const mtlLoader = new MTLLoader(this.loadingManager);
    const objLoader = new OBJLoader(this.loadingManager);
    return new Promise((resolve, reject) => {
      const position = mainShelfInfo
        ? ShelfView3D.getShelfPositionRelatedWithMainShelf(shelfInfo, mainShelfInfo)
        : { x: shelfType.width.replace(',', '.') / 10 / 2, z: 0 };
      if (shelfType.material_path) {
        mtlLoader.load(config.apiUrl + shelfType.material_path, (materials) => {
          materials.preload();
          objLoader.setMaterials(materials);

          objLoader.load(config.apiUrl + shelfType.model_path, (object) => {
            object.scale.set(0.1, 0.1, 0.1);
            object.position.set(position.x, 0, position.z);
            this.scene.add({ ...object, isWall: true });
            resolve(object);
          });
        }, () => {}, (err) => {
          console.log(err, 'object error');
          reject();
        });
      } else {
        objLoader.load(config.apiUrl + shelfType.model_path, (object) => {
          object.scale.set(0.1, 0.1, 0.1);
          object.position.set(position.x, 0, position.z);
          this.scene.add({ ...object, isWall: true });
          resolve(object);
        }, () => {}, (err) => {
          console.log(err, 'wall error');
          reject();
        });
      }
    });
  }

  createBox(
    height,
    depth,
    width,
    x,
    y,
    z,
    angle,
    color,
    dataForShowing,
    opacity = 1,
    isNeighbor = false,
  ) {
    const defaultShelfColor = 0xb8b8b8;
    const geometry = new THREE.CubeGeometry(
      width,
      height,
      depth,
    );
    const material = new THREE.MeshStandardMaterial({
      roughness: 0.5,
      metalness: 0.6,
      emissive: 0x0,
      color: color || defaultShelfColor,
      transparent: opacity < 1,
      opacity,
    });
    const cube = new THREE.Mesh(geometry, material);
    cube.rotation.y = rotationByAngle(angle);
    cube.position.set(x, y, z);
    cube.receiveShadow = true;
    cube.castShadow = true;
    cube.isNeighbor = isNeighbor;
    cube.data = dataForShowing;
    this.scene.add(cube);
    return cube;
  }

  createNeighboursShelves(shelfInfoList, mainShelf) {
    shelfInfoList.forEach((shelfInfo) => {
      const {
        height, length: depth, width, angle, color
      } = shelfInfo;
      const { x, z } = ShelfView3D.getShelfPositionRelatedWithMainShelf(shelfInfo, mainShelf);
      this.createBox(
        height / 10,
        depth / 10,
        width / 10,
        x,
        height / 10 / 2,
        z,
        angle,
        color,
        {
          shelfId: shelfInfo.id,
          shelfType: shelfInfo.shelf_type ? shelfInfo.shelf_type.shelve_type : '',
          gangNumber: shelfInfo.id,
          name: shelfInfo.shelf_name,
          productsExisting: !!shelfInfo.planogram,
        },
        0.7,
        true
      );
    });
  }

  async createShelf(shelfInfo, mainShelfInfo) {
    if (shelfInfo !== null) {
      if (shelfInfo.shelf_type && shelfInfo.shelf_type.model_path) {
        return this.createShelfModel(shelfInfo, mainShelfInfo)
          .catch(async () => this.createBackWall(shelfInfo));
      }
      // this.createRightWall();
      // this.createLeftWall();
      return this.createBackWall(shelfInfo);
    }
    return null;
  }

  showProductAfterFindingOnMap() {
    const {
      finding,
      shelfInfo,
      productsList,
    } = this.props;

    if (finding.productName !== null && finding.shelvesIds.length !== 0) {
      const shelfId = finding.shelvesIds.find(item => item === shelfInfo.id);
      if (shelfId) {
        const productForShowing = productsList.find(
          product => product.product.description1_de === finding.productName,
        );
        if (productForShowing) {
          this.showFoundedProduct(productForShowing);
        }
      }
    }
  }

  animate() {
    if (this.renderer) {
      window.requestAnimationFrame(this.animate);

      if (!this.mouseDown) {
        this.raycaster.setFromCamera(this.mouse, this.camera);
        const intersects = this.getIntersectedObjects();
        this.setHighlighting(intersects);
      }

      this.renderer.render(this.scene, this.camera);
      this.orbitControlsShelf.update();
    }
  }

  addSpotLight(lightPosition, targetPosition, useHelper) {
    const spotLight = new THREE.SpotLight(0xffffff, 0.4);
    spotLight.position.set(lightPosition[0], lightPosition[1], lightPosition[2]);
    spotLight.castShadow = true;
    spotLight.target.position.set(targetPosition[0], targetPosition[1], targetPosition[2]);
    spotLight.shadow.camera.near = 10;
    spotLight.shadow.camera.far = 100;
    spotLight.angle = 1;
    spotLight.penumbra = 0.4;
    /*
    let gui = new dat.GUI();
    gui.add(spotLight.position, 'x', -100, 100);
    gui.add(spotLight.position, 'y', -100, 100);
    gui.add(spotLight.position, 'z', -100, 100);
    */
    if (useHelper) {
      const helper = new THREE.CameraHelper(spotLight.shadow.camera);
      this.scene.add(helper);
    }
    this.scene.add(spotLight.target);
    this.scene.add(spotLight);
  }

  addLight() {
    const light = new THREE.AmbientLight(0xffffff, 0.7);
    this.scene.add(light);
    this.addSpotLight([this.offsetX, 47, 42], [this.offsetX, 0, 6], false);
  }

  showFoundedProduct(product, colorHex) {
    const coloredAllChildren = (item) => {
      if (item.material) {
        const color = colorHex || item.firstColor;
        item.material.color.setHex(color);
      }
      if (item.children) {
        item.children.forEach((child) => {
          coloredAllChildren(child, colorHex);
        });
      }
    };
    const self = this;
    this.getObjectsForIntersect().forEach((object) => {
      if (object.productId && object.productId === product.product.id) {
        self.setState({
          selectedProductInfo: object.isChild ? object.parent.productInfo : object.productInfo,
          productInfoIsOpen: true,
        });

        if (object.material) {
          if (object.material[0]) {
            object.material.forEach(material => material.color.setHex(0x26e605));
          } else {
            object.material.color.setHex(0x26e605);
          }
        } else {
          coloredAllChildren(object, 0x26e605);
        }
      }
      if (object.productId !== product.product.id && object.firstColor) {
        if (object.material) {
          if (object.material[0]) {
            object.material.forEach(material => material.color.setHex(String(object.firstColor)));
          } else {
            object.material.color.setHex(String(object.firstColor));
          }
        } else {
          coloredAllChildren(object);
        }
      }
    });
  }

  prepareScene(shelfInfo, productList, neighbours) {
    if (shelfInfo !== null) {
      this.offsetX = shelfInfo.width / 10 / 2;
    }
    this.create3DView(shelfInfo, productList, neighbours);

    /* mouse events */
    const shelfCanvas = document.getElementById('shelf-view-canvas');
    shelfCanvas.addEventListener('mousedown', this.onDocumentMouseDown);
    shelfCanvas.addEventListener('mouseup', this.onDocumentMouseUp);
    shelfCanvas.addEventListener('mousemove', this.onDocumentMouseMove);
    shelfCanvas.addEventListener('click', this.onDocumentMouseClick);

    /* touch events */
    shelfCanvas.addEventListener('touchstart', this.onDocumentTouch);
    shelfCanvas.addEventListener('touchend', this.onDocumentTouchEnd);
  }

  openBasket() {
    this.setState({
      basketIsOpen: true,
    });
  }

  removeSelectFromFoundedProducts() {
    const self = this;
    self.setState({
      selectedProductInfo: null,
      productInfoIsOpen: false,
    });
    this.getObjectsForIntersect().forEach((object) => {
      if (object.isProduct && object.material) {
        if (object.material[0]) {
          object.material.forEach(material => material.color.setHex(String(object.firstColor)));
        } else {
          object.material.color.setHex(String(object.firstColor));
        }
      }
    });
  }

  /*  changeCameraPosition = (name, value) => {
    this.camera.position[name] += value;
    this.setState({ [name]: this.camera.position[name] });
    this.orbitControlsShelf.update();
  };

  changeTargetPosition = (name, value) => {
    // this.cube.rotation.y += (value / 10) * Math.PI / 2;
    this.orbitControlsShelf.target[name] += value;
    this.setState({ [`target_${name}`]: this.orbitControlsShelf.target[name] });
    this.orbitControlsShelf.update();
  };

   createControls = (x, y, z, changePosition, absolutePosition = 'left') => (
     <div style={{
       position: 'absolute',
       display: 'flex',
       flexDirection: 'column',
       top: 84,
       left: absolutePosition === 'left' ? 30 : undefined,
       right: absolutePosition !== 'left' ? 30 : undefined,
       justifyContent: 'space-between',
       alignContent: 'space-between',
       width: 70,
       height: 92,
       backgroundColor: 'white',
     }}
     >
       <div
         style={{
           display: 'flex',
           flexDirection: 'column',
           justifyContent: 'space-between',
           alignContent: 'space-between',
         }}
       >
         <div
           style={{
             display: 'flex',
             flexDirection: 'row',
             justifyContent: 'space-between',
             alignContent: 'space-between',
           }}
         >
           <button style={{ width: 30, height: 30 }} title="+x" onClick={() => { changePosition('x', 1); }}>+x</button>
           <button style={{ width: 30, height: 30 }} title="-x" onClick={() => { changePosition('x', -1); }}>-x</button>
         </div>
         <label>{x}</label>
       </div>

       <div
         style={{
           display: 'flex',
           flexDirection: 'column',
           justifyContent: 'space-between',
           alignContent: 'space-between',
         }}
       >
         <div
           style={{
             display: 'flex',
             flexDirection: 'row',
             justifyContent: 'space-between',
             alignContent: 'space-between',
           }}
         >
           <button style={{ width: 30, height: 30 }} title="+y" onClick={() => { changePosition('y', 1); }}>+y</button>
           <button style={{ width: 30, height: 30 }} title="-y" onClick={() => { changePosition('y', -1); }}>-y</button>
         </div>
         <label>{y}</label>
       </div>

       <div
         style={{
           display: 'flex',
           flexDirection: 'column',
           justifyContent: 'space-between',
           alignContent: 'space-between',
         }}
       >
         <div
           style={{
             display: 'flex',
             flexDirection: 'row',
             justifyContent: 'space-between',
             alignContent: 'space-between',
           }}
         >
           <button style={{ width: 30, height: 30 }} title="+z" onClick={() => { changePosition('z', 1); }}>+z</button>
           <button style={{ width: 30, height: 30 }} title="-z" onClick={() => { changePosition('z', -1); }}>-z</button>
         </div>
         <label>{z}</label>
       </div>
     </div>
   ); */

  closeBasket() {
    this.setState({
      basketIsOpen: false,
    });
  }

  render() {
    const {
      basketIsOpen, windowMode, selectedProductInfo, productInfoIsOpen, selectedShelfInfo,
      // x, y, z, target_x, target_y, target_z,
      isLoading,
      progressItemsLoaded,
      progressItemsTotal,
      isFetching
    } = this.state;
    const { error } = this.props;
    if (error !== null) {
      return (
        <Error
          code={error.code}
          message={error.message}
        />
      );
    }

    return (
      <div
        style={{
          height: '100vh',
          width: '100%',
        }}
        id="3d-shelf-view-container"
      >
        {/*      {this.createControls(x, y, z, this.changeCameraPosition)}
      {this.createControls(target_x, target_y, target_z, this.changeTargetPosition, 'right')} */}
        <BasketDialog
          isOpen={basketIsOpen}
          close={this.closeBasket}
        />
        <Toolbar
          showGoBack
          openBasket={this.openBasket}
        >
          <Search
            searchItems={this.findProduct}
            removeFounded={this.removeSelectFromFoundedProducts}
            itemsFound={this.showFoundedProduct}
          />
        </Toolbar>
        <ProductInfo
          windowMode={windowMode}
          productInfo={selectedProductInfo}
          isOpen={productInfoIsOpen}
          onCloseClick={this.closeInfo}
        />
        <ShelfInfo
          selected="shelf"
          isOpen={!!selectedShelfInfo}
          fetching={!selectedShelfInfo}
          selectedShelfInfo={selectedShelfInfo}
          selectedObjectInfo={null}
          onCloseClick={this.closeInfo}
          onOpenShelfViewButtonClick={this.goToShelf}
          openShelfButtonText="Go to the shelf"
        />
        <Loader
          isActive={isLoading || isFetching}
          progress={33}
          title="Loading models and textures..."
          subTitle={`${progressItemsLoaded}/${progressItemsTotal}`}
        />
{/*        <Zoomer
          camera={this.camera}
          initialPosition={this.initialPosition}
          update={() => {
            this.orbitControlsShelf.update();
            this.renderer.render(this.scene, this.camera);
          }}
        />*/}
      </div>
    );
  }
}

ShelfView3D.propTypes = {
  dispatch: PropTypes.func.isRequired,
  windowMode: PropTypes.bool,
  shelfInfo: PropTypes.shape(),
  match: PropTypes.shape().isRequired,
  finding: PropTypes.shape(),
  productsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  history: PropTypes.shape().isRequired,
  error: PropTypes.shape(),
};
ShelfView3D.defaultProps = {
  windowMode: false,
  finding: null,
  shelfInfo: null,
  error: null,
};

function mapStateToProps(state) {
  return {
    productsList: shelfSelectors.getProducts(state),
    shelfInfo: shelfSelectors.getShelfInfo(state),
    neighbours: shelfSelectors.getNeighbours(state),
    finding: shelfSelectors.getFinding(state),
    error: shelfSelectors.getErrorIfExist(state),
  };
}

export default connect(mapStateToProps)(withRouter(ShelfView3D));
