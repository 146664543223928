import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import { useTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import { ArrowBack, Visibility, ShoppingBasket } from '@material-ui/icons';

import { BasketItem } from '../BasketDialog/BasketDialog';

import * as storeSelectors from '../../store/store/reducer';
import * as basketSelectors from '../../store/shoppingbasket/reducer';

const text = {
  liveview_button: 'LiveView',
  back_button: 'Go Back',
  cart_button: 'Cart'
};

const useStyles = makeStyles(theme => ({
  logoWithTitle: {
    /* [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    }, */
  },
  logo: {
    height: theme.spacing(3)
  },
  title: {
    paddingTop: theme.spacing(0.5),
    color: theme.palette.common.white,
    fontSize: theme.spacing(2),
    fontWeight: 'bold'
  },
  toolbar: {
    padding: theme.spacing(1, 2),
    lineHeight: theme.spacing(0.125),
    backgroundColor: theme.palette.background.holger,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    }
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    /* [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    }, */
  },
  centerGridItem: {
    [theme.breakpoints.down('sm')]: {
      order: 1
    }
  },
  hideButtonText: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  secondaryButton: {
    boxShadow: 'none',
  }
}));

const countCartItems = (items, countQuantity) => {
  let count = 0;
  if (countQuantity) {
    items.forEach((item) => { count += item.quantity; });
  } else count = items.length;
  return count;
};

const AccentBadge = withStyles(theme => ({
  badge: {
    backgroundColor: theme.palette.accent.main
  }
}))(Badge);

function Toolbar({
  children,
  logo,
  showGoBack,
  openBasket,
  liveViewURL,
  storeNumber,
  productsInBasket
}) {
  const history = useHistory();

  const theme = useTheme();

  const classes = useStyles();

  const basketItemsCount = openBasket && productsInBasket
    ? countCartItems(productsInBasket, true) : 0;

  return (
    <AppBar
      id="headerToolbar"
      position="fixed"
      className={classes.toolbar}
    >
      <Grid
        container
        spacing={theme.spacing(0.25)}
        justify="space-between"
        alignItems="center"
      >

        <Grid item xs={6} sm={6} md={3} className={classes.logoWithTitle}>
          <Box>
            <img alt={logo.alt} src={logo.src} className={classes.logo} />
          </Box>
          <Box className={classes.title}>
            {storeNumber}
          </Box>
        </Grid>

        { children && (
          <Grid item xs={12} sm={12} md={6} className={classes.centerGridItem}>
            {children}
          </Grid>
        ) }

        <Grid item xs={6} sm={6} md={3}>
          <Box className={classes.buttons}>

            { liveViewURL && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => history.push(liveViewURL)}
                className={classes.secondaryButton}
              >
                <Visibility fontSize="small" />
                <span className={classes.hideButtonText}>
                  <span>&nbsp;</span>
                  {text.liveview_button}
                </span>
              </Button>
            ) }

            { showGoBack && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => history.goBack()}
                className={classes.secondaryButton}
              >
                <ArrowBack fontSize="small" />
                <span className={classes.hideButtonText}>
                  <span>&nbsp;</span>
                  {text.back_button}
                </span>
              </Button>
            ) }

            { openBasket && (
              <AccentBadge
                color="secondary"
                badgeContent={basketItemsCount}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={openBasket}
                  style={{ marginLeft: theme.spacing(1) }}
                  className={classes.secondaryButton}
                >
                  <ShoppingBasket fontSize="small" />
                  <span className={classes.hideButtonText}>
                    <span>&nbsp;</span>
                    {text.cart_button}
                  </span>
                </Button>
              </AccentBadge>
            ) }

          </Box>
        </Grid>

      </Grid>
    </AppBar>
  );
}

class Logo {
  src;

  alt;
}

Toolbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  showGoBack: PropTypes.bool,
  logo: PropTypes.shape(Logo),
  liveViewURL: PropTypes.string,
  openBasket: PropTypes.func,
  storeNumber: PropTypes.string.isRequired,
  productsInBasket: PropTypes.arrayOf(PropTypes.shape(BasketItem)).isRequired
};

Toolbar.defaultProps = {
  children: null,
  showGoBack: false,
  logo: { src: '/logo.png', alt: 'easyBoard' },
  liveViewURL: null,
  openBasket: null,
};

function mapStateToProps(state) {
  return {
    storeNumber: storeSelectors.getStoreNumber(state),
    productsInBasket: basketSelectors.getProductsFromBasket(state),
  };
}

export default connect(mapStateToProps)(Toolbar);
