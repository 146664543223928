import axios from 'axios';
import * as types from './actionTypes';
import config from '../../config';

export function loadProductsOnShelf(id) {
  return (dispatch, getState) => {
    return axios({
      method: 'GET',
      url: `${config.apiUrl}/api/get-products-on-shelf?with_neighbours=1`,
      params: {
        shelfId: id,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: types.LOAD_PRODUCTS,
            data: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404 && error.response.data.message) {
          dispatch({
            type: types.LOAD_ERROR,
            code: error.response.status,
            errorMessage: error.response.data.message,
          });
        } else {
          dispatch({
            type: types.LOAD_ERROR,
            code: error.response.status,
            errorMessage: 'Unexpected error',
          });
        }
      });
  };
}

export function removeProducts() {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_PRODUCTS });
  };
}

export function setFetching(isFetching) {
  return (dispatch, getState) => {
    dispatch({ type: types.SET_FETCHING, isFetching });
  };
}

export function setFinding(shelves, productName) {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_FINDING,
      productName,
      shelvesIds: shelves && shelves.length
        ? shelves.map(shelf => shelf.id)
        : [],
    });
  };
}
