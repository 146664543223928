import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import QRCode from 'qrcode.react';
import { withTheme } from '@material-ui/styles';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import * as storeSelectors from '../../store/store/reducer';

const styles = theme => ({
  button: {
    margin: 10,
  },
  shelfinfoPaper: {
    position: 'absolute',
    minWidth: '20vw',
    padding: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    maxWidth: '90vw',
    width: 'max-content',
    opacity: 0.9,
    zIndex: theme.zIndex.modal,
    top: '50%',
    transform: 'translate(0, -50%)',
    maxHeight: '100vh',
  },
  shelfinfoClose: {
    position: 'absolute',
    right: 20,
    top: 10,
    left: 'auto',
  },
  shelfinfoButtons: {
    display: 'flex',
    justifyContent: 'center'
  },
  shelfinfoContent: {
    display: 'flex',
  },
  shelfinfoContentRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  shelfinfoQR: {
    marginRight: 20,
    fontSize: 0,
  },
  shelfinfoObjectInfo: {
    marginBottom: 20,
  },
});


class ShelfInfo extends Component {
  renderContent = () => {
    const {
      classes, onCloseClick, selected, selectedShelfInfo
    } = this.props;
    return (
      <Paper className={classes.shelfinfoPaper}>
        <IconButton className={classes.shelfinfoClose} edge="end" color="inherit" onClick={onCloseClick} aria-label="close">
          <CloseIcon />
        </IconButton>
        {
        selected === 'shelf'
          ? (
            <div className={classes.shelfinfoContent} style={{ flexWrap: 'wrap' }}>
              {
                  selectedShelfInfo.productsExisting
                    ? this.renderQRCode()
                    : null
                }
              <div className={classes.shelfinfoContentRight}>
                { this.renderShelfInfo() }
                { this.renderButtons() }
              </div>
            </div>
          )
          : this.renderObjectInfo()
      }
      </Paper>
    );
  };

  renderButtons = () => {
    const {
      classes, selectedShelfInfo, onOpenShelfViewButtonClick, openShelfButtonText, findPathToShelf
    } = this.props;
    return (
      <div className={classes.shelfinfoButtons} style={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>
        <Button
          color="primary"
          variant="contained"
          style={{ marginRight: 10, marginBottom: 10 }}
          onClick={onOpenShelfViewButtonClick}
          disabled={!selectedShelfInfo.productsExisting}
          title={selectedShelfInfo.productsExisting ? 'Shelf View' : 'The shelf is empty'}
        >
          { openShelfButtonText }
        </Button>
        {
          findPathToShelf
            ? (
              <Button
                style={{ marginBottom: 10 }}
                color="primary"
                variant="contained"
                onClick={findPathToShelf}
              >
Find path to shelf
              </Button>
            )
            : ''
        }
      </div>
    );
  };

  renderQRCode = () => {
    const { classes } = this.props;
    return (
      <div className={classes.shelfinfoQR} style={{ marginBottom: 10 }}>
        <QRCode value={this.getURLForShelfView()} />
      </div>
    );
  };

  renderObjectInfo = () => {
    const { classes, selectedObjectInfo } = this.props;
    return (
      <div className={classes.shelfinfoObjectInfo}>
        <div>
          <span style={{ fontWeight: 600 }}>Type name: </span>
          <span>{selectedObjectInfo.typeName}</span>
        </div>
        <div>
          <span style={{ fontWeight: 600 }}>Object Type: </span>
          <span>{selectedObjectInfo.objectType}</span>
        </div>
      </div>
    );
  };

  renderShelfInfo = () => {
    const { classes, selectedShelfInfo } = this.props;
    return (
      <div className={classes.shelfinfoObjectInfo}>
        <div>
          <span style={{ fontWeight: 600 }}>Name: </span>
          <span>{selectedShelfInfo.name}</span>
        </div>
        <div>
          <span style={{ fontWeight: 600 }}>Gang: </span>
          <span>{selectedShelfInfo.gangNumber}</span>
        </div>
        {
          selectedShelfInfo.shelfType
            ? (
              <div>
                <span style={{ fontWeight: 600 }}>Type: </span>
                <span>{selectedShelfInfo.shelfType}</span>
              </div>
            )
            : ''
        }
      </div>
    );
  };

  getURLForShelfView = () => {
    const { selectedShelfInfo } = this.props;
    if (window.location.href[window.location.href.length - 1] === '/') {
      return `${window.location.href}shelf-view/${selectedShelfInfo.shelfId}`;
    }
    return `${window.location.href}/shelf-view/${selectedShelfInfo.shelfId}`;
  };

  render() {
    const { isOpen, selectedShelfInfo, selectedObjectInfo } = this.props;
    if (isOpen && (selectedShelfInfo || selectedObjectInfo)) {
      return this.renderContent();
    }
    return '';
  }
}

ShelfInfo.propTypes = {
  isOpen: PropTypes.bool,
  selectedShelfInfo: PropTypes.shape(),
  selectedObjectInfo: PropTypes.shape(),
  classes: PropTypes.shape().isRequired,
  onCloseClick: PropTypes.func,
  onOpenShelfViewButtonClick: PropTypes.func,
  findPathToShelf: PropTypes.func,
  selected: PropTypes.string,
  openShelfButtonText: PropTypes.string,
};
ShelfInfo.defaultProps = {
  isOpen: false,
  selectedShelfInfo: null,
  selectedObjectInfo: null,
  onCloseClick: null,
  onOpenShelfViewButtonClick: null,
  findPathToShelf: null,
  selected: 'shelf',
  openShelfButtonText: 'Open shelf view',
};

function mapStateToProps(state) {
  return {
    storeContent: storeSelectors.getStoreContent(state),
    storeSizes: storeSelectors.getStoreSizes(state),
  };
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(withTheme(ShelfInfo))));
