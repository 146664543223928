import { createMuiTheme } from '@material-ui/core/styles';

const theme = {
  palette: {
    type: 'light',
    common: { black: '#000', white: '#fff' },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    background: {
      paper: '#fff',
      default: '#fafafa',
      holger: '#90caf9'
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A700: '#616161',
      A100: '#d5d5d5',
      A400: '#303030',
      A200: '#aaaaaa'
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    action: {
      hoverOpacity: 0.08,
      hover: 'rgba(0, 0, 0, 0.08)',
      selected: 'rgba(0, 0, 0, 0.14)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      active: 'rgba(0, 0, 0, 0.54)'
    },
    primary: {
      main: '#6493C5', // #4db6ac
      light: 'rgb(131, 168, 208)',
      dark: 'rgb(70, 102, 137)',
      contrastText: '#fff'
    },
    secondary: {
      main: '#49c485', // #42a5f5
      light: 'rgb(109, 207, 157)',
      dark: 'rgb(51, 137, 93)',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    accent: {
      main: '#ec5141'
    }
  },
};

export default createMuiTheme(theme);
