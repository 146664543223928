import React from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as legendSelectors from '../../store/legend/reducer';
import config from '../../config';
import styled from 'styled-components';



const StyledTabs = styled(Tabs)`
     .MuiSvgIcon-fontSizeSmall {
       font-size: 2em;
     }
`;


const useStyles = makeStyles(theme => ({
  legendPaper: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.primary.main,
  },
  legendTabPaper: {
    width: '100%',
    padding: theme.spacing(1),
  },
  legendTab: {
    minWidth: 'auto',
    width: 100,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    padding: 0,
    overflow: 'unset',
  },
  legendTabContainer: {
    width: '100%',
    minHeight: theme.spacing(12),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  legendIcon: {
    maxWidth: theme.spacing(4),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.25),
  },
  legendPsevdoIcon: {
    Width: theme.spacing(4),
    height: theme.spacing(4),
  },
  legendTabName: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    lineHeight: 1.4,
    wordBreak: 'break-word'
  },
  legendTabColor: {
    width: '100%',
    marginTop: theme.spacing(0.25),
    border: '1px solid rgba(0,0,0,0.3)',
    height: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
}));

const useStylesTabs = makeStyles(theme => ({
  scrollButtons: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[10],
  },
  scrollButtonsDesktop: {
      backgroundColor:theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[10],
  }
}));


const LegendPanel = (props) => {
  const classes = useStyles(props);
  const classesTabs = useStylesTabs(props);

  const theme = useTheme()
  const { selectedLegendItemId, onLegendItemClick, legends = [] } = props;

  return (
    <Paper className={classes.legendPaper}>
      <StyledTabs
      classes={classesTabs}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      aria-label="Legend"
      value={false}
        >
      {legends.map(
          legendItem => (
              <Tab className={classes.legendTab}
                  key={legendItem.name}
                  label={
                      <Paper elevation={3} className={classes.legendTabPaper}>
                        <div className={classes.legendTabContainer}>
                          <div className={classes.legendIcon}>
                            {legendItem.icon && <img width={theme.spacing(4)} height={theme.spacing(4)} src={config.apiUrl + legendItem.icon} alt="" />}
                          </div>
                          <div className={classes.legendTabName}>
                            {legendItem.name}
                          </div>
                          <div className={classes.legendTabColor} style={{ background: legendItem.value }} ></div>
                      </div>
                      </Paper>
                  }
                  value={legendItem.id}
                  style={{ background: selectedLegendItemId && selectedLegendItemId === legendItem.id ?  theme.palette.action.selected : theme.palette.background.paper }}
                  onClick={() => { if (selectedLegendItemId !== legendItem.id) { onLegendItemClick(legendItem);}}}
                />
          )
      )}}
      </StyledTabs>
	</Paper>
  );
};

function mapStateToProps(state) {
  return {
    legends: legendSelectors.getLegend(state),
	  };
}

export default connect(mapStateToProps)(withRouter(LegendPanel));
