import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { ShoppingCart, DeleteForever } from '@material-ui/icons';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import config from '../../config';
import * as cartActions from '../../store/shoppingbasket/actions';
import * as cartSelectors from '../../store/shoppingbasket/reducer';
import * as storeSelectors from '../../store/store/reducer';
import './index.css';

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  productinfoPaper: {
    position: 'absolute',
    minWidth: '20vw',
    padding: theme.spacing(2.5),
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    maxWidth: '90vw',
    width: 'max-content',
    overflow: 'hidden',
    zIndex: theme.zIndex.modal,
    top: '50%',
    transform: 'translate(0, -50%)',
    maxHeight: '100vh',
  },
  productinfoClose: {
    position: 'absolute',
    right: theme.spacing(2.5),
    top: theme.spacing(1),
    left: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  productinfoText: {
    paddingBottom: theme.spacing(2.5),
    paddingRight: theme.spacing(5),
  },
  productinfoQuantityContainer: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  productinfoQuantity: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  productinfoQuantityField: {
    width: theme.spacing(5),
  },
  productinfoBasketContainer: {
    marginTop: theme.spacing(1),
  },
  productinfoBasket: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  productinfoBasketDel: {
    marginLeft: theme.spacing(1),
    color: 'white',
  },
  productinfoImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 0,
    marginLeft: theme.spacing(-2.5),
    marginRight: theme.spacing(-2.5),
    marginBottom: theme.spacing(2.5),
  },
  productinfoImage: {
    maxWidth: '100%',
    maxHeight: '30vh'
  },
  arContainer: {
    backgroundColor: theme.palette.divider,
    padding: theme.spacing(0.5),
    borderRadius: 5,
    marginRight: theme.spacing(1),
    border: `solid 1px ${theme.palette.divider}`
  }
});

class ProductInfo extends Component {
  state = {
    quantity: '1',
  };

  render() {
    if (this.props.isOpen && this.props.productInfo) {
      return (
        <div
          id="shelf-info"
          style={{
            width: !this.props.windowMode ? '100%' : '90%',
          }}
        >
          { this.renderContent() }
        </div>
      );
    }
    return null;
  }

  renderContent = () => (
    <Paper className={this.props.classes.productinfoPaper}>
      <IconButton className={this.props.classes.productinfoClose} edge="end" color="primary" onClick={this.props.onCloseClick} aria-label="close" style={{ backgroundColor: '#fff' }}>
        <CloseIcon />
      </IconButton>
      { this.renderImage() }
      { this.renderInfo() }
      { this.renderBasket() }
    </Paper>
  );

  getProductInBasket = () => this.props.productsInCart
    .find(product => product.id === this.props.productInfo.product.id);

  isArAvailable = () => {
    const a = document.createElement('a');
    return a.relList && a.relList.supports && a.relList.supports('ar');
  };

  renderBasket = () => (
    <div className={this.props.classes.productinfoBasketContainer}>
      <Grid container>
        { this.isArAvailable() && this.props.productInfo.product.model && this.props.productInfo.product.model.usdz
          ? (
            <a
              href={this.props.productInfo.product.model.usdz}
              className={`${this.props.classes.arContainer} ar-button`}
            >
              <img
                alt=""
                src="/images/arkit-glyph.svg"
                style={{
                  height: '1.5rem',
                  width: '1.5rem',
                }}
              />
            </a>
          )
          : '' }
        <Grid item>
          <Button
            className={this.props.classes.onAddToCardClick}
            variant="outlined"
            color="primary"
            onClick={this.onAddToCardClick}
          >
            <ShoppingCart />
              Add to cart
          </Button>
        </Grid>
        <Grid item className={this.props.classes.productinfoQuantityContainer}>
          <span className={this.props.classes.productinfoQuantity}>
              Quantity
          </span>
          <TextField
            className={this.props.classes.productinfoQuantityField}
            id="quantity-product"
            value={this.state.quantity}
            onChange={(event) => {
              this.setState({
                quantity: event.target.value
              });
            }}
          />
        </Grid>

      </Grid>
      {
          this.getProductInBasket()
            ? (
              <Grid container className={this.props.classes.productinfoBasket}>
                <span>
                    Product already in cart
                </span>
                <span>
                    (
                  {this.getProductInBasket().quantity}
)
                </span>
                <Button
                  className={this.props.classes.productinfoBasketDel}
                  style={{
                    background: '#f92c2c',
                  }}
                  onClick={this.removeFromCartClick}
                >
                  <DeleteForever />
                </Button>
              </Grid>
            )
            : null
        }
    </div>
  );

  removeFromCartClick = () => {
    this.props.dispatch(
      cartActions.removeProduct(
        this.props.productInfo.product.id
      )
    );
  };

  onAddToCardClick = () => {
    if (this.getProductInBasket()) {
      this.props.dispatch(
        cartActions.addQuantityToProduct(
          this.props.productInfo.product.id,
          Number(this.state.quantity)
        )
      );
    } else {
      this.props.dispatch(
        cartActions.addProduct(
          this.props.productInfo.product,
          Number(this.state.quantity)
        )
      );
    }
    this.setState({ quantity: '1' });
  };

  renderImage = () => {
    if (this.props.productInfo.product.image) {
      return (
        <div className={this.props.classes.productinfoImageContainer}>
          <img
            className={this.props.classes.productinfoImage}
            src={config.apiUrl + this.props.productInfo.product.image}
            alt={this.props.productInfo.product.product_number}
          />
        </div>
      );
    }
    return null;
  };

  renderInfo = () => (
    <div className={this.props.classes.productinfoText}>
      <div>
        <span style={{ fontWeight: 600 }}>Number: </span>
        <span>{this.props.productInfo.product.product_number}</span>
      </div>
      <div>
        <span style={{ fontWeight: 600 }}>Description: </span>
        <span>{this.props.productInfo.product.description1_de}</span>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    productsInCart: cartSelectors.getProductsFromBasket(state),
    storeContent: storeSelectors.getStoreContent(state),
    storeSizes: storeSelectors.getStoreSizes(state),
  };
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(ProductInfo)));
