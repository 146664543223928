export const objectAngle = (object) => {
  const angle = object.angle ? Number(object.angle) % 360 : 0;
  const relativeAngle = Math.abs(angle % 360);
  return relativeAngle < 0 ? 360 + relativeAngle : relativeAngle;
};

export const getSizesByAngle = (object) => {
  const w = Number(object.width);
  const d = object.depth ? Number(object.depth) : Number(object.height);
  const angle = objectAngle(object);
  return angle === 270 || angle === 90 ? [d, w] : [w, d];
};

export const getCenterPositionByAngle = (object) => {
  const [w, d] = getSizesByAngle(object);
  const x = Number(object.x) + w / 2;
  const z = Number(object.y) + d / 2;
  return [x, z];
};

const normalizeAngle = (startWallPoint, endWallPoint, angle) => {
  let result;
  if (startWallPoint.x < endWallPoint.x && startWallPoint.y > endWallPoint.y) {
    result = -((360 * Math.PI / 180) - angle) - (90 * Math.PI / 180);
  } else if (startWallPoint.x > endWallPoint.x && startWallPoint.y > endWallPoint.y) {
    result = -((180 * Math.PI / 180) + angle) - (90 * Math.PI / 180);
  } else if (startWallPoint.x > endWallPoint.x && startWallPoint.y < endWallPoint.y) {
    result = -((180 * Math.PI / 180) - angle) - (90 * Math.PI / 180);
  } else if (startWallPoint.x < endWallPoint.x && startWallPoint.y < endWallPoint.y) {
    result = -(angle) - (90 * Math.PI / 180);
  } else {
    result = -(angle) - (90 * Math.PI / 180);
  }
  return result;
};

export const rotationByAngle = (angle) => {
  switch (angle) {
    case '90': return Math.PI * (3 / 2);
    case '180': return Math.PI;
    case '270': return Math.PI / 2;
    case '0': default: return 0;
  }
};

export default normalizeAngle;
