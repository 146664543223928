import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  fetching: false,
  storeNumber: null,
  width: null,
  height: null,
  storeContent: [],
  fullStoreContent: [],
  error: {
    status: false,
    code: null,
    message: null,
  },
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_FETCHING:
      return state.merge({
        fetching: action.isFetching,
      });
    case types.LOAD_STORE:
      return state.merge({
        storeNumber: action.data.store_number,
        width: action.data.width,
        height: action.data.height,
        storeContent: action.data.mapContent,
        storeId: action.data.id,
        ...action.data,
      });
    case types.LOAD_FULL_CONTENT:
      return state.merge({
        fullStoreContent: action.data,
      });
    case types.LOAD_STORE_ERROR:
      return state.merge({
        error: {
          status: true,
          code: action.code,
          message: action.errorMessage,
        },
      });
    default:
      return state;
  }
}

export function getFetching(state) {
  return state.store.fetching;
}

export function getErrorIfExist(state) {
  return state.store.error.status
    ? state.store.error
    : null;
}

export function getStore(state) {
  return state.store;
}

export function getStoreId(state) {
  return state.store.storeId;
}

export function getStoreNumber(state) {
  if (state.store && state.store.storeNumber) {
    return state.store.storeNumber;
  }
  return '';
}

export function getStoreContent(state) {
  if (state.store.storeContent
   && state.store.storeContent.length
   && state.store.storeContent.length !== 0) {
    return state.store.storeContent;
  }
  return [];
}

export function getFullStoreContent(state) {
  if (state.store.fullStoreContent
   && state.store.fullStoreContent.length
   && state.store.fullStoreContent.length !== 0) {
    return state.store.fullStoreContent;
  }
  return [];
}

export function getStoreSizes(state) {
  if (state.store && state.store.width && state.store.height) {
    return {
      height: state.store.height,
      width: state.store.width,
    };
  }
  return {
    height: null,
    width: null,
  };
}
