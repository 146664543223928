import React from 'react';

class Error extends React.PureComponent {
  render() {
    return (
      <div>
        <div style={{ fontSize: 40 }}>
          <span style={{ marginRight: 10 }}>
            Error:
          </span>
          <span>
            {this.props.code}
          </span>
        </div>
        <div>
          {this.props.message}
        </div>
      </div>
    );
  }
}

export default Error;
