import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as THREE from 'three';
import OrbitControls from 'three-orbitcontrols';
import clsx from 'clsx';
import PF from 'pathfinding';
import { withTheme } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import {
  ControlCamera,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  // KeyboardArrowDown as KeyboardArrowDownIcon
} from '@material-ui/icons';

import ShelfInfo from '../ShelfInfo';
import Search from '../Search';
import * as shelfActions from '../../store/shelf/actions';
import SettingsPanel from '../SettingsPanel';
import LegendPanel from '../LegendPanel';
import BasketDialog from '../BasketDialog';
import Toolbar from '../Toolbar';
import normalizeAngle, { getSizesByAngle, getCenterPositionByAngle } from '../../helpers/helper3d';
import { Store, StoreSize, StoreObject } from '../../model/Store';

import * as storeSelectors from '../../store/store/reducer';
import { searchProducts } from '../../store/store/actions';

const RENDERER_CLEAR_COLOR = 0xa3a3a3;
const LIGHT_COLOR = 0xffffff;
const SPECULAR_COLOR = 0xebebeb;
const INTERSECTED_COLOR = 0xacc4c4;
const PATH_COLOR = 0xe20d0d;
const SKY_COLOR = 0xcff4f9;
// const FOUNDED_SHELF_COLOR = 0xC5ff81;
const FLOOR_PLANE_COLOR = 0xafafaf;
const FLOOR_TYPE_COLOR = '0';
const FLOOR_TYPE_IMAGE = '1';

const PATH_WIDTH = 3;

const CLICK_THRESHOLD = 5;
const TOUCH_THRESHOLD = 3;

const MATRIX_PRECISION = 0.1;
const MIN_PASS_WIDTH = 55; // centimeters

const DEBUG_PATH_FINDING = false;

const styles = theme => ({
  drawer: {
    backgroundColor: `#${SKY_COLOR.toString(16)}`,
  },
  fabOverDrawer: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(6),
    width: theme.spacing(6),
    height: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    boxShadow: theme.shadows[1],
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  freeFab: {
    margin: `0 auto -${theme.spacing(2.5)}px auto !important`,
  },
  normalStateIcon: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  rotatedStateButton: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaper: {
    width: '100%',
    height: theme.spacing(21),
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderTop: 'none'
  },
  centerView: {
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(4),
  }
});

class StoreMap extends Component {
  mouse = null;

  raycaster = null;

  renderer = null;

  scene = null;

  camera = null;

  orbitControls = null;

  INTERSECTED = null;

  currentObjectOnCast = null;

  selectObject = null;

  mouseDown = false;

  matrix = [];

  displayCount = 1;

  state = {
    shelfInfoIsOpen: false,
    shelfInfoFetching: false,
    selectedShelfInfo: null,
    settingsPanelIsOpen: false,
    displaysList: [],
    currentDisplayId: null,
    selectedLegendItemId: null,
    basketIsOpen: false,
    headerHeight: 0,
    mobileOpen: false,
  }

  componentDidMount() {
    this.matrixCreate();

    this.create3DView();

    const canvas = document.getElementsByTagName('canvas')[0];
    canvas.addEventListener('mousedown', this.onDocumentMouseDown);
    canvas.addEventListener('mouseup', this.onDocumentMouseUp);
    canvas.addEventListener('mousemove', this.onDocumentMouseMove);
    canvas.addEventListener('click', this.onDocumentMouseClick);
    /* touch events */
    canvas.addEventListener('touchstart', this.onDocumentTouch);
    canvas.addEventListener('touchend', this.onDocumentTouchEnd);

    window.addEventListener('resize', this.onWindowResize);

    this.setCurrentDisplay();
  }

  componentWillUnmount() {
    this.mouse = null;
    this.raycaster = null;
    this.renderer = null;
    this.scene = null;
    this.camera = null;
    this.orbitControls.enabled = false;
    this.orbitControls = null;
    this.displayCount = 1;
    window.removeEventListener('resize', this.onWindowResize);
  }

  onDocumentMouseDown = (event) => {
    this.mouseDown = true;
    this.moveStartPosition = { x: event.clientX, y: event.clientY };
  };

  onDocumentMouseUp = () => {
    this.mouseDown = false;
  };

  onDocumentMouseMove = (event) => {
    event.preventDefault();
    this.mouse.x = ((event.clientX - this.renderer.domElement.offsetLeft)
      / this.renderer.domElement.width) * 2 - 1;
    this.mouse.y = -((event.clientY - this.renderer.domElement.offsetTop)
      / this.renderer.domElement.height) * 2 + 1;
  };

  onDocumentMouseClick = (event) => {
    this.moveEndPosition = { x: event.clientX, y: event.clientY };
    const diff = this.calcMaxDistance(this.moveStartPosition, this.moveEndPosition);
    if (diff > CLICK_THRESHOLD) return;

    if (this.INTERSECTED && this.selectObject !== this.INTERSECTED.uuid) {
      const self = this;
      this.getObjectsForIntersect().forEach((object) => {
        if (self.INTERSECTED.uuid === object.uuid) {
          if (object.isShelf) {
            this.removeOldPathsToShelfIfExists();
            self.setState({
              selected: 'shelf',
              selectedShelfInfo: {
                name: object.name,
                gangNumber: object.shelfId, // object.gangNumber,
                shelfId: object.shelfId,
                productsExisting: object.productsExisting,
              },
              shelfInfoIsOpen: true,
            });
          } else if (object.isObject) {
            self.setState({
              selected: 'object',
              selectedObjectInfo: {
                typeName: object.objectTypeName,
                objectType: object.objectType,
              },
              selectedShelfInfo: null,
              shelfInfoIsOpen: true,
            });
          }
        }
        if (object.uuid !== self.INTERSECTED.uuid && object.firstColor) {
          object.material.color.setHex(String(object.firstColor));
        }
      });
      this.selectObject = this.INTERSECTED.uuid;
    }
  };

  onDocumentTouch = (event) => {
    event.preventDefault();
    this.mouseDown = true;
    const x = event.touches[0].clientX;
    const y = event.touches[0].clientY;
    this.mouse.x = ((x - this.renderer.domElement.offsetLeft)
      / this.renderer.domElement.width) * 2 - 1;
    this.mouse.y = -((y - this.renderer.domElement.offsetTop)
      / this.renderer.domElement.height) * 2 + 1;
    this.moveStartPosition = { x, y };
  };

  onDocumentTouchEnd = (event) => {
    this.mouseDown = false;

    const x = event.changedTouches[0].clientX;
    const y = event.changedTouches[0].clientY;

    this.mouse.x = ((x - this.renderer.domElement.offsetLeft)
      / this.renderer.domElement.width) * 2 - 1;
    this.mouse.y = -((y - this.renderer.domElement.offsetTop)
      / this.renderer.domElement.height) * 2 + 1;

    this.moveEndPosition = { x, y };

    const diff = this.calcMaxDistance(this.moveStartPosition, this.moveEndPosition);
    if (diff > TOUCH_THRESHOLD) return;

    this.calculateIntersectedObject();
    this.onDocumentMouseClick(event);
  };

  setCurrentDisplay() {
    const { currentDisplayId } = this.props;

    const { displaysList } = this.state;

    let currentDisplay = null;
    if (currentDisplayId) {
      const displayList = displaysList.filter(
        display => Number(display.displayId) === Number(currentDisplayId)
      );
      currentDisplay = displayList.length ? displayList[0] : null;
    } else {
      currentDisplay = displaysList.length > 0
        ? displaysList[displaysList.length - 1] : null;
    }
    if (currentDisplay) {
      this.setState({
        currentDisplayId: currentDisplay.displayId,
      });
    }
  }

  calcMaxDistance = (p1, p2) => Math.max(Math.abs(p1.x - p2.x), Math.abs(p1.y - p2.y));

  calculateIntersectedObject = () => {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObjects(this.getObjectsForIntersect());
    if (intersects.length > 0) {
      if (this.INTERSECTED !== intersects[0].object) {
        if (this.currentObjectOnCast !== intersects[0].object.uuid
          && this.INTERSECTED && this.selectObject !== this.INTERSECTED.uuid) {
          this.INTERSECTED.material.color.setHex(String(this.INTERSECTED.previewColor));
        }
        this.INTERSECTED = intersects[0].object;
        this.currentObjectOnCast = this.INTERSECTED.uuid;
        this.INTERSECTED.intersectCount = this.INTERSECTED.intersectCount
          ? this.INTERSECTED.intersectCount + 1 : 1;
        if (this.INTERSECTED.intersectCount === 1) {
          this.INTERSECTED.firstColor = this.INTERSECTED.material.color.getHex();
        }
        this.INTERSECTED.previewColor = this.INTERSECTED.material.color.getHex();
        this.INTERSECTED = intersects[0].object;
        this.INTERSECTED.material.color.setHex(INTERSECTED_COLOR);
      }
    } else {
      if (this.INTERSECTED && this.INTERSECTED.uuid !== this.selectObject) {
        this.INTERSECTED.material.color.setHex(String(this.INTERSECTED.previewColor));
      }
      this.INTERSECTED = null;
    }
  };

  handleDrawerToggle = () => {
    this.setState(prevState => ({ mobileOpen: !prevState.mobileOpen }));
  };

  centeredStore = () => {
    const { storeSizes } = this.props;
    const { width, height } = storeSizes;
    this.camera.position.set(width / 2, width * 2, height * 4);
    this.orbitControls.target.set(width / 2, 0, height / 2);
  };

  openBasket = () => this.setState({
    basketIsOpen: true,
  });

  closeBasket = () => this.setState({
    basketIsOpen: false,
  });

  removeLegendSprites = () => {
    const itemsForDelete = [];
    this.scene.children.forEach((item) => {
      if (item.isLegendSprite) {
        itemsForDelete.push(item);
      }
    });
    if (itemsForDelete.length !== 0) {
      itemsForDelete.forEach(item => this.scene.remove(item));
    }
  };

  removeSelectFromLegendItem = () => {
    this.setState({ selectedLegendItemId: null });
    this.removeLegendSprites();
  };

  showShelfWithSelectGroup = (legendItem) => {
    this.removeLegendSprites();
    this.setState({ mobileOpen: false, selectedLegendItemId: legendItem.id });
    this.scene.children.forEach((item) => {
      if ((item.isShelf || item.isObject) && item.legendId && item.legendId === legendItem.id) {
        const spritePath = legendItem.icon ? legendItem.icon : 'location-pin.png';
        const spriteMap = new THREE.TextureLoader().load(spritePath);
        const spriteMaterial = new THREE.SpriteMaterial({ map: spriteMap, color: 0xffffff });
        const sprite = new THREE.Sprite(spriteMaterial);
        sprite.position.set(item.position.x, 0.5, item.position.z);
        sprite.scale.x = 0.5;
        sprite.scale.y = 0.5;
        sprite.scale.z = 0.5;
        sprite.isLegendSprite = true;
        this.scene.add(sprite);
        setTimeout(this.removeSelectFromLegendItem, 5000);
      }
    });
  };

  onOpenShelfViewButtonClick = () => {
    const { history, storeId } = this.props;
    const { selectedShelfInfo } = this.state;
    history.push(`/${storeId}/shelf-view/${selectedShelfInfo.shelfId}`);
  };

  getDisplayCoordinatesIfExist = () => {
    const { currentDisplayId } = this.state;
    let coordinates = null;
    this.scene.children.forEach((object) => {
      if (object.isDisplay && object.displayId && object.displayId === currentDisplayId) {
        coordinates = {
          x: Math.round(object.position.x * 10),
          y: Math.round(object.position.z * 10)
        };
      }
    });
    return coordinates;
  };

  removeOldPathsToShelfIfExists = () => {
    const itemsForRemove = [];
    this.scene.children.forEach((item) => {
      if (item.isPath) {
        itemsForRemove.push(item);
      }
    });
    if (itemsForRemove.length !== 0) {
      itemsForRemove.forEach(item => this.scene.remove(item));
    }
  };

  findPathToOneShelf = () => {
    const { selectedShelfInfo } = this.state;
    this.removeOldPathsToShelfIfExists();
    this.findPathToShelf(selectedShelfInfo.shelfId);
  };

  findPathToShelf = (shelfId) => {
    if (this.matrix === null) return;

    const display = this.getDisplayCoordinatesIfExist();
    if (display === null) return;

    let foundedShelf = null;
    this.scene.children.forEach((object) => {
      if (object.shelfId && String(object.shelfId) === String(shelfId)) {
        foundedShelf = object;
      }
    });
    if (!foundedShelf) return;

    // find empty side of shelf to find path from
    const posX = Math.round(foundedShelf.position.x * 10);
    const posZ = Math.round(foundedShelf.position.z * 10);

    let shelf = null;

    const halfWidth = Math.round(foundedShelf.geometry.parameters.width * 10 / 2);
    const halfDepth = Math.round(foundedShelf.geometry.parameters.depth * 10 / 2);

    const correct = Math.round(MIN_PASS_WIDTH / 2 * MATRIX_PRECISION) + 1;

    const mw = this.matrix[0].length - 1;
    const mh = this.matrix.length - 1;

    const sides = [1, 2];
    if (halfWidth >= halfDepth) sides.push(3, 4);
    else sides.unshift(4, 3);

    do {
      shelf = { x: posX, y: posZ };

      const side = sides.shift() || null;

      switch (side) {
        case 1: shelf.y = posZ + halfDepth + correct; break;
        case 2: shelf.y = posZ - halfDepth - correct; break;
        case 3: shelf.x = posX + halfWidth + correct; break;
        case 4: shelf.x = posX - halfWidth - correct; break;
        default: return;
      }

      if (shelf.x < 0) shelf.x = 0;
      if (shelf.x > mw) shelf.x = mw;
      if (shelf.y < 0) shelf.y = 0;
      if (shelf.y > mh) shelf.y = mh;
    } while (this.matrix[shelf.y][shelf.x] === 1);

    const grid = new PF.Grid(this.matrix);

    // grid.setWalkableAt(shelf.x, shelf.y, true);

    const finder = new PF.JumpPointFinder({
      heuristic: PF.Heuristic.euclidean,
    });

    const path = finder.findPath(display.x, display.y, shelf.x, shelf.y, grid);

    const matrixUnit = 100 * MATRIX_PRECISION;

    const coordCorrect = MATRIX_PRECISION / 2 * matrixUnit;

    if (path.length) path.push([posX, posZ]);

    let prev = [display.x, display.y];

    path.forEach((item) => {
      const start = {
        x: (prev[0] + coordCorrect) / matrixUnit,
        z: (prev[1] + coordCorrect) / matrixUnit,
        y: 0.1
      };
      const end = {
        x: (item[0] + coordCorrect) / matrixUnit,
        z: (item[1] + coordCorrect) / matrixUnit,
        y: 0.1
      };

      const geometry = new THREE.Geometry();
      geometry.vertices.push(new THREE.Vector3(start.x, start.y, start.z));
      geometry.vertices.push(new THREE.Vector3(end.x, end.y, end.z));

      const material = new THREE.LineBasicMaterial({ color: PATH_COLOR, linewidth: PATH_WIDTH });

      const line = new THREE.Line(geometry, material);
      line.isPath = true;

      this.scene.add(line);

      prev = item;
    });
  };

  closeShelfInfo = () => this.setState({
    shelfInfoIsOpen: false,
  });

  create3DView = () => {
    this.createScene();
    this.animate();
    this.addPlane();
    this.createObjects();
    this.addLight();
  };

  animate = () => {
    if (this.renderer) {
      window.requestAnimationFrame(this.animate);

      if (!this.mouseDown) {
        this.calculateIntersectedObject();
      }

      this.renderer.render(this.scene, this.camera);
      this.orbitControls.update();
    }
  };

  getObjectsForIntersect = () => {
    const objects = [];
    this.scene.children.forEach((item) => {
      if (item.isShelf || item.isObject) {
        objects.push(item);
      }
    });
    return objects;
  };

  updateSceneSizeProportion = (width, height) => {
    if (this.renderer == null || this.camera == null) return;
    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  };

  onWindowResize = () => {
    const headerToolbar = document.getElementById('headerToolbar');
    const sceneContainer = document.getElementById('3d-view-container');
    const drawerContainer = document.getElementById('drawer-container');

    const offset = headerToolbar.offsetHeight;
    this.setState({ headerHeight: offset });

    const containerHeight = sceneContainer.clientHeight
                          - headerToolbar.clientHeight
                          - drawerContainer.offsetHeight;
    this.updateSceneSizeProportion(sceneContainer.clientWidth, containerHeight);
  };

  createScene = () => {
    const { store } = this.props;

    const headerToolbar = document.getElementById('headerToolbar');
    const sceneContainer = document.getElementById('3d-view-container');
    const drawerContainer = document.getElementById('drawer-container');

    const offset = headerToolbar.offsetHeight;
    this.setState({ headerHeight: offset });

    this.mouse = new THREE.Vector2();

    this.raycaster = new THREE.Raycaster(), this.INTERSECTED; // eslint-disable-line

    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setClearColor(RENDERER_CLEAR_COLOR, 1);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    const containerHeight = sceneContainer.clientHeight
                          - headerToolbar.clientHeight
                          - drawerContainer.offsetHeight;
    this.renderer.setSize(sceneContainer.clientWidth, containerHeight);

    sceneContainer.insertBefore(this.renderer.domElement, drawerContainer);

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(SKY_COLOR);

    this.camera = new THREE.PerspectiveCamera(30, window.innerWidth / window.innerHeight, 1, 10000);
    this.camera.aspect = sceneContainer.clientWidth / containerHeight;
    this.camera.updateProjectionMatrix();

    this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

    this.centeredStore();

    /* if (height) {
       let gui = new dat.GUI();
       console.log(this.camera)
       gui.add(this.camera.position, 'x', -200, 200);
       gui.add(this.camera.position, 'y', -200, 200);
       gui.add(this.camera.position, 'z', -200, 200);
     } */

    this.orbitControls.maxPolarAngle = Math.PI / 2.1;
    if (!store.rotate && store.rotate_angle) {
      const angle = Number(store.rotate_angle);
      const halfAngle = angle / 2;
      const halfAngleRad = halfAngle * Math.PI / 180;

      this.orbitControls.minAzimuthAngle = -halfAngleRad;
      this.orbitControls.maxAzimuthAngle = halfAngleRad;
    }

    this.orbitControls.update();

    const searchInput = document.getElementById('searchInput');
    if (searchInput) {
      searchInput.addEventListener('focus', this.onSearchInputFocus);
      searchInput.addEventListener('blur', this.onSearchInputBlur);
    }
  };

  onSearchInputFocus = () => {
    this.orbitControls.enablePan = false;
  };

  onSearchInputBlur = () => {
    this.orbitControls.enablePan = true;
  };

  addPlane = () => {
    const { store, storeSizes } = this.props;
    const { width, height } = storeSizes;
    const x = width / 2;
    const y = height / 2;
    const geometry = new THREE.PlaneGeometry(width, height, 0, 0);

    let optionsMaterial = null;
    if (store.floor_type) {
      if (store.floor_type === FLOOR_TYPE_COLOR && store.floor_color) {
        optionsMaterial = {
          color: store.floor_color,
          side: THREE.DoubleSide
        };
      } else if (store.floor_type === FLOOR_TYPE_IMAGE && store.floor_texture) {
        const texture = new THREE.TextureLoader().load(store.floor_texture);
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(width, height);
        optionsMaterial = {
          side: THREE.DoubleSide,
          map: texture,
        };
      }
    } else {
      optionsMaterial = {
        color: FLOOR_PLANE_COLOR,
        side: THREE.DoubleSide
      };
    }

    const material = new THREE.MeshPhongMaterial(optionsMaterial);
    const plane = new THREE.Mesh(geometry, material);
    plane.receiveShadow = true;
    plane.castShadow = false;
    plane.isMainPlane = true;
    plane.rotateX(Math.PI / 2);
    plane.position.set(x, 0, y);
    this.scene.add(plane);
  };

  createObjects = () => {
    const { storeContent } = this.props;
    if (storeContent && storeContent.length !== 0) {
      storeContent.forEach((object) => {
        if (object.type !== 'ruler' && object.type !== 'image') {
          if (object.nodeName === 'polygon' && object.type === 'wall') {
            this.createWalls(object);
          } else {
            this.createShelf(object);
          }
        }
      });
    }
  };

  createShelf = (object) => {
    const { displaysList } = this.state;
    const geometry = new THREE.CubeGeometry(
      Number(object.width) / 100,
      0.2,
      Number(object.depth) / 100,
    );
    const material = new THREE.MeshPhongMaterial({
      shininess: 20,
      specular: SPECULAR_COLOR,
      reflectivity: 1,
    });
    const cube = new THREE.Mesh(geometry, material);
    const [cx, cz] = getCenterPositionByAngle(object);
    const x = cx / 100;
    const y = cz / 100;
    cube.position.set(x, 0.1, y);
    cube.material.color.setHex(`0x${object.fill.substr(1)}`);
    cube.mainColor = `0x${object.fill.substr(1)}`;
    cube.receiveShadow = true;
    cube.castShadow = true;
    if (object.type === 'shelve') {
      cube.isShelf = true;
      cube.name = object.name;
      cube.gangNumber = object.gangNumber;
      cube.shelfId = object.id;
      cube.legendId = object.colorGroupId;
    } else if (object.type === 'otherObject') {
      cube.isObject = true;
      cube.objectType = object.objectType ? object.objectType : null;
      cube.objectTypeName = object.objectTypeName ? object.objectTypeName : null;
      cube.legendId = object.colorGroupId ? object.colorGroupId : null;
      if (object.objectType === 'display') {
        displaysList.push({
          ...object,
          displayId: this.displayCount,
        });
        this.setState({
          currentDisplayId: this.displayCount,
        });
        cube.isDisplay = true;
        cube.displayId = this.displayCount;
        this.displayCount += 1;
      }
    }
    cube.productsExisting = !!object.planogramName;
    cube.rotation.y = Math.floor(Number(object.angle) / 90) * Math.PI / 2;
    this.scene.add(cube);
  };

  createWalls = (object) => {
    const pointsArray = object.points.split(' ').map(item => item.split(','));

    for (let i = 0; i < pointsArray.length; i += 1) {
      const startWallPoint = {
        x: Number(pointsArray[i][0]),
        y: Number(pointsArray[i][1]),
      };
      let endWallPoint = null;
      if (pointsArray[i + 1]) {
        endWallPoint = {
          x: Number(pointsArray[i + 1][0]),
          y: Number(pointsArray[i + 1][1]),
        };
      } else {
        endWallPoint = {
          x: Number(pointsArray[0][0]),
          y: Number(pointsArray[0][1]),
        };
      }
      this.createWall(startWallPoint, endWallPoint, object);
    }
  };

  createWall = (startWallPoint, endWallPoint, object) => {
    const XLineLength = Math.abs(startWallPoint.x - endWallPoint.x);
    const YLineLength = Math.abs(startWallPoint.y - endWallPoint.y);
    const wallLength = this.getWallLength(XLineLength, YLineLength);
    const midPointWallCoordinates = this.getMidPointOfWall(startWallPoint, endWallPoint);
    let angle = this.getAngle(YLineLength, wallLength);

    const geometry = new THREE.CubeGeometry(
      object.strokeWidth / 100, 0.2, wallLength / 100 + (object.strokeWidth / 100)
    );
    const material = new THREE.MeshPhongMaterial({
      shininess: 20,
      specular: SPECULAR_COLOR,
      reflectivity: 1,
    });
    const cube = new THREE.Mesh(geometry, material);
    const x = midPointWallCoordinates.x / 100;
    const y = midPointWallCoordinates.y / 100;
    cube.position.set(x, 0.1, y);
    cube.material.color.setHex(`0x${object.stroke.substr(1)}`);
    cube.receiveShadow = true;
    cube.castShadow = true;
    cube.isDraggable = true;

    angle = normalizeAngle(startWallPoint, endWallPoint, angle);

    cube.rotateY(angle);
    this.scene.add(cube);
  };

  getAngle = (YLineLength, wallLength) => Math.asin(YLineLength / wallLength);

  getMidPointOfWall = (startWallPoint, endWallPoint) => ({
    x: (startWallPoint.x + endWallPoint.x) / 2,
    y: (startWallPoint.y + endWallPoint.y) / 2,
  });

  getWallLength = (XLineLength, YLineLength) => Math.sqrt((XLineLength ** 2) + (YLineLength ** 2));

  addLight = () => {
    const { storeSizes } = this.props;
    let light = new THREE.AmbientLight(LIGHT_COLOR, 0.7);
    this.scene.add(light);
    light = new THREE.DirectionalLight(LIGHT_COLOR, 0.13);
    light.position.set(storeSizes.width / 2, 10, storeSizes.height / 2);
    light.target.position.set(storeSizes.width / 2, 0, storeSizes.height / 2);
    this.scene.add(light);
    this.scene.add(light.target);
    // this.addSpotLight([-3, 5, 0], [11, 0, 6], false);
  };

  addSpotLight = (lightPosition, targetPosition, helper) => {
    const spotLight = new THREE.SpotLight(LIGHT_COLOR, 0.8);
    spotLight.position.set(lightPosition[0], lightPosition[1], lightPosition[2]);
    spotLight.castShadow = true;
    spotLight.target.position.set(targetPosition[0], targetPosition[1], targetPosition[2]);
    spotLight.shadow.camera.near = 10;
    spotLight.shadow.camera.far = 100;
    spotLight.angle = 1;
    spotLight.penumbra = 0.4;
    if (helper) {
      const newHelper = new THREE.CameraHelper(spotLight.shadow.camera);
      this.scene.add(newHelper);
    }
    this.scene.add(spotLight.target);
    this.scene.add(spotLight);
  };

  removeFoundedShelves = () => {
    const { dispatch } = this.props;
    dispatch(shelfActions.setFinding(null, null));
    this.removeOldPathsToShelfIfExists();
    const itemsForDelete = [];
    const itemsForChangeColor = [];
    this.scene.children.forEach((item) => {
      const link = item;
      if (item.isShelf && item.shelfId && item.foundedShelf) {
        link.foundedShelf = false;
        itemsForChangeColor.push(item);
      }
      if (item.isFoundedShelfSprite) {
        itemsForDelete.push(item);
      }
    });
    if (itemsForDelete.length !== 0) {
      itemsForDelete.forEach(item => this.scene.remove(item));
    }
  };

  shelvesFound = (product, productName) => {
    const { dispatch } = this.props;
    if (product && product.shelfIdList && product.shelfIdList.length) {
      dispatch(shelfActions.setFinding(product.shelfIdList.map(id => ({ id })), productName));
      this.removeOldPathsToShelfIfExists();
      this.showFoundShelves(product.shelfIdList);
      // todo правильнее будет показать или все, или ближайший
      this.findPathToShelf(product.shelfIdList[0]);
    }
  };

  showFoundShelves = (shelfIds) => {
    this.removeFoundedShelves();
    this.removeLegendSprites();
    this.scene.children.forEach((item) => {
      const link = item;
      if (item.isShelf && item.shelfId) {
        shelfIds.forEach((shelfId) => {
          if (String(item.shelfId) === (shelfId)) {
            const spriteMap = new THREE.TextureLoader().load('location-pin.png');
            const spriteMaterial = new THREE.SpriteMaterial({ map: spriteMap, color: 0xffffff });
            const sprite = new THREE.Sprite(spriteMaterial);
            sprite.position.set(item.position.x, 0.5, item.position.z);
            sprite.scale.x = 0.5;
            sprite.scale.y = 0.5;
            sprite.scale.z = 0.5;
            sprite.isFoundedShelfSprite = true;
            this.scene.add(sprite);
            link.foundedShelf = true;
            // item.material.color.setHex(FOUNDED_SHELF_COLOR);
          }
        });
      }
    });
  };

  searchShelves = (value) => {
    const { storeId } = this.props;
    return searchProducts(value, storeId);
  }

  matrixCreate() {
    const { storeSizes, storeContent } = this.props;

    if (!storeSizes.width || !storeSizes.height) return;

    const matrixUnit = 100 * MATRIX_PRECISION;

    // multiply by 100 to convert meters to centimeters
    const fixedWidth = storeSizes.width * matrixUnit;
    const fixedDepth = storeSizes.height * matrixUnit;

    const matrix = [];

    for (let i = 0; i < fixedDepth; i += 1) {
      const row = [];
      for (let j = 0; j < fixedWidth; j += 1) {
        row.push(0);
      }
      matrix.push(row);
    }

    let canvas;
    let ctx;

    if (DEBUG_PATH_FINDING) {
      canvas = document.createElement('canvas');
      canvas.width = fixedWidth;
      canvas.height = fixedDepth;
      ctx = canvas.getContext('2d');
    }

    storeContent.forEach((item) => {
      if (item.type !== 'image' && item.objectType !== 'display') {
        const toRoundNumber = stringNumber => Math.round(Number(stringNumber) / matrixUnit);

        const correct = Math.floor(
          (Math.floor(MIN_PASS_WIDTH / 2) - matrixUnit) * MATRIX_PRECISION
        );

        const [w, d] = getSizesByAngle(item);
        const width = toRoundNumber(w);
        const depth = toRoundNumber(d);
        const x = toRoundNumber(item.x);
        const z = toRoundNumber(item.y);

        const xFrom = Math.max(x - correct, 0);
        const xTo = Math.min(Math.round(x + width + correct), fixedWidth - 1);
        const zFrom = Math.max(z - correct, 0);
        const zTo = Math.min(Math.round(z + depth + correct), fixedDepth - 1);

        if (xFrom >= 0 && xTo >= 0 && zFrom >= 0 && zTo >= 0) {
          for (let j = zFrom; j < zTo; j += 1) {
            for (let i = xFrom; i < xTo; i += 1) {
              if (i >= 0 && j >= 0 && i < fixedWidth && j < fixedDepth) {
                matrix[j][i] = 1;
                if (DEBUG_PATH_FINDING) {
                  ctx.fillStyle = 'black';
                  ctx.fillRect(i, j, 1, 1);
                }
              }
            }
          }
        }
      }
    });

    if (DEBUG_PATH_FINDING) {
      const bitmap = canvas.toDataURL('image/png');
      window.open(bitmap, '_blank');
    }

    this.matrix = matrix;
  }

  render() {
    const { classes, theme, storeId } = this.props;

    const {
      mobileOpen,
      basketIsOpen,
      settingsPanelIsOpen,
      displaysList,
      currentDisplayId,
      selected,
      selectedShelfInfo,
      selectedObjectInfo,
      selectedLegendItemId,
      shelfInfoIsOpen,
      shelfInfoFetching,
      headerHeight
    } = this.state;

    const containerStyle = {
      paddingTop: headerHeight,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      backgroundColor: '#eeeeee',
      height: '100%',
      width: '100%',
    };

    const legend = (
      <LegendPanel
        selectedLegendItemId={selectedLegendItemId}
        onLegendItemClick={this.showShelfWithSelectGroup}
        removeSelectFromLegendItem={this.removeSelectFromLegendItem}
      />
    );

    return (
      <div style={containerStyle} id="3d-view-container">
        <Fab
          size="small"
          color="primary"
          style={{ top: headerHeight + theme.spacing(4) }}
          className={classes.centerView}
          onClick={() => this.centeredStore()}
        >
          <ControlCamera />
        </Fab>

        <BasketDialog
          isOpen={basketIsOpen}
          close={this.closeBasket}
        />

        <Toolbar
          openBasket={this.openBasket}
          liveViewURL={`/${storeId}/live-view`}
        >
          <Search
            itemsFound={this.shelvesFound}
            removeFounded={this.removeFoundedShelves}
            searchItems={this.searchShelves}
          />
        </Toolbar>

        <SettingsPanel
          isOpen={settingsPanelIsOpen}
          displaysList={displaysList}
          onCloseClick={this.toggleSettings}
          currentDisplayId={currentDisplayId}
          showCurrentDisplayOnMap={() => this.showCurrentDisplayOnMap(currentDisplayId)}
          changeDisplay={this.changeDisplay}
        />

        <ShelfInfo
          selected={selected}
          isOpen={shelfInfoIsOpen}
          fetching={shelfInfoFetching}
          selectedShelfInfo={selectedShelfInfo}
          selectedObjectInfo={selectedObjectInfo}
          onCloseClick={this.closeShelfInfo}
          findPathToShelf={this.findPathToOneShelf}
          onOpenShelfViewButtonClick={this.onOpenShelfViewButtonClick}
        />
        <Box component="nav" id="drawer-container" className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Box className={`${classes.fabOverDrawer} ${classes.freeFab}`} onClick={this.handleDrawerToggle}>
              <KeyboardArrowUpIcon htmlColor={theme.palette.primary.contrastText} />
            </Box>
            <Drawer
              variant="persistent"
              anchor="bottom"
              open={mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <Box className={classes.fabOverDrawer} onClick={this.handleDrawerToggle}>
                <KeyboardArrowUpIcon
                  htmlColor={theme.palette.primary.contrastText}
                  className={clsx(classes.normalStateIcon, {
                    [classes.rotatedStateButton]: mobileOpen,
                  })}
                />
              </Box>
              {legend}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              anchor="bottom"
              variant="permanent"
              open
            >
              {legend}
            </Drawer>
          </Hidden>

        </Box>
      </div>
    );
  }
}

StoreMap.propTypes = {
  classes: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  theme: PropTypes.shape().isRequired,
  dispatch: PropTypes.func,
  store: PropTypes.shape(Store),
  storeId: PropTypes.number,
  storeSizes: PropTypes.shape(StoreSize),
  storeContent: PropTypes.arrayOf(PropTypes.shape(StoreObject)),
  currentDisplayId: PropTypes.string
};

StoreMap.defaultProps = {
  dispatch: null,
  store: null,
  storeId: null,
  storeSizes: null,
  storeContent: null,
  currentDisplayId: null
};

function mapStateToProps(state) {
  return {
    store: storeSelectors.getStore(state),
    storeId: storeSelectors.getStoreId(state),
    storeSizes: storeSelectors.getStoreSizes(state),
    storeContent: storeSelectors.getStoreContent(state),
  };
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(withTheme(StoreMap))));
