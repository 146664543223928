import axios from 'axios';
import * as types from './actionTypes';
import config from '../../config';

export function loadLegend() {
  return (dispatch, getState) => {
    return axios({
      method: 'GET',
      url: `${config.apiUrl}/api/get-legend`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data) {
          dispatch({ type: types.LOAD_LEGEND, data: response.data });
        }
      })
      .catch((error) => {});
  };
}

export function setFetching() {
  return (dispatch, getState) => {
    dispatch({ type: types.SET_FETCHING });
  };
}
