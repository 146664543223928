import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Close, Search as SearchIcon } from '@material-ui/icons';
import { fade } from '@material-ui/core/styles/colorManipulator';

const SEARCH_BUTTON = 'Search';
const SEARCH_LABEL = 'Search products';

const useStyles = makeStyles(theme => ({
  searchWrapStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchResetStyle: {
    color: theme.palette.primary.dark,
    backgroundColor: fade(theme.palette.background.paper, 0.4),
    borderRadius: theme.spacing(0.5),
    position: 'absolute',
    right: theme.spacing(0.5),
    bottom: theme.spacing(1),
    cursor: 'pointer',
  },
  searchResultsStyle: {
    color: theme.palette.common.black,
    backgroundColor: fade(theme.palette.background.default, 0.67),
    backdropFilter: `blur(${theme.spacing(1)}px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'auto',
    maxHeight: '50vh',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    zIndex: 1,
  },
  searchButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main, // primary
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark, // primary
    },
  }
}));

function Search({
  itemsFound,
  removeFounded,
  searchItems,
}) {
  const [value, setValue] = useState('');
  const [itemList, setItemList] = useState([]);
  const [fetching, setFetching] = useState(false);
  const theme = useTheme();
  const classes = useStyles();

  const internalSearchItems = (text) => {
    if (fetching) return;
    const searchText = (text && typeof text === 'string') ? text : value;
    setFetching(true);
    searchItems(searchText).then((items) => {
      setItemList(items);
    }).catch((ex) => {
      // eslint-disable-next-line no-console
      console.warn(ex);
      setItemList([]);
    }).finally(() => setFetching(false));
  };

  const onRemoveValueClick = () => {
    setValue('');
    setItemList([]);
    removeFounded();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      internalSearchItems();
    }
  };

  return (
    <Box className={classes.searchWrapStyle}>
      <Autocomplete
        value={value}
        items={itemList}
        getItemValue={item => item.label}
        onChange={(e) => {
          setValue(e.target.value);
          internalSearchItems(e.target.value);
        }}
        onSelect={(val, item) => {
          setValue(val);
          itemsFound(item, val);
        }}
        wrapperStyle={{ width: '100%' }}
        renderInput={props => (
          <Box position="relative">
            <TextField
              InputProps={{
                inputProps: {
                  onKeyPress: handleKeyPress,
                  id: 'searchInput',
                  ...props
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              color="primary"
              value={value}
              label={SEARCH_LABEL}
              margin="dense"
              fullWidth
            />
            { value !== '' ? (
              <Close
                onClick={onRemoveValueClick}
                className={classes.searchResetStyle}
              />
            ) : null}
          </Box>
        )}
        renderMenu={items => (
          items != null && items.length
            ? (
              <Box className={classes.searchResultsStyle}>
                <Grid
                  container
                  spacing={theme.spacing(0.25)}
                  style={{ height: '100%' }}
                  justify="center"
                  alignItems="stretch"
                >
                  <Grid item xs={12} md={6}>{items}</Grid>
                </Grid>
              </Box>
            )
            : <div />
        )}
        renderItem={(item, isHighlighted) => (
          <Box
            key={item.id}
            style={{
              background: isHighlighted
                ? theme.palette.divider
                : 'transparent',
              padding: theme.spacing(0.75, 1, 1),
            }}
          >
            {item.label}
          </Box>
        )}
      />

      <Button
        variant="contained"
        onClick={internalSearchItems}
        className={classes.searchButton}
      >
        {SEARCH_BUTTON}
      </Button>

    </Box>
  );
}

Search.propTypes = {
  itemsFound: PropTypes.func.isRequired,
  removeFounded: PropTypes.func.isRequired,
  searchItems: PropTypes.func.isRequired,
};

Search.defaultProps = {
};

export default Search;
