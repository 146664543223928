import React from 'react';
import { Close } from '@material-ui/icons';
import { Button, Paper, Divider, useTheme } from '@material-ui/core';
import DisplaySection from './DisplaySection';

function SettingsPanel({
  isOpen,
  onCloseClick,
  showCurrentDisplayOnMap,
  displaysList,
  currentDisplayId,
  changeDisplay,
}) {

  const theme = useTheme();

  const panelStyle = {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '20vw',
    height: '100vh',
    minWidth: '240px',
    background: theme.palette.background.default,
  };

  if (isOpen) {
    return (
      <Paper square style={panelStyle}>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onCloseClick}>
            <Close />
          </Button>
        </div>

        <Divider />

        <DisplaySection
          showCurrentDisplayOnMap={showCurrentDisplayOnMap}
          displaysList={displaysList}
          currentDisplayId={currentDisplayId}
          changeDisplay={changeDisplay}
        />

        <Divider />

      </Paper>
    );
  }
  return '';
}

export default SettingsPanel;
