import React, {Component} from 'react';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withRouter} from 'react-router';
import * as storeActions from '../../store/store/actions';
import MainContainer from '../MainContainer';
import * as storeSelectors from '../../store/store/reducer';
import * as legendSelectors from '../../store/legend/reducer';
import LiveView from '../../components/LiveView';
import Error from '../../components/Error';
import {Typography} from "@material-ui/core";

class LiveViewContainer extends Component {
  componentDidMount() {
    const {
      match,
    } = this.props;

    if (match && match.params && match.params.storeId) {
      if (this.props.storeId) {
        this.loadFullContent(match.params.storeId);
      } else {
        this.loadStore(match.params.storeId)
      }
    }
  }

  loadStore = storeId => {
      const {dispatch} = this.props;
      dispatch(storeActions.setFetching(true));
      Promise.all([
        dispatch(storeActions.loadStore(storeId, true)),
        dispatch(storeActions.loadFullStoreContent(storeId))
      ]).then(() => {
        dispatch(storeActions.setFetching(false));
      });
  };

  loadFullContent = storeId => {
    const {dispatch} = this.props;
    dispatch(storeActions.setFetching(true));
    dispatch(storeActions.loadFullStoreContent(storeId)).then(() => {
      dispatch(storeActions.setFetching(false));
    });
  };

  renderContent = () => {
    if (this.props.fetchingStore) {
      return <React.Fragment>
        <Typography variant={"h6"} gutterBottom>Loading store ...</Typography>
        <CircularProgress/>
      </React.Fragment>;
    }
    if (this.props.error !== null) {
      return (
        <Error
          code={this.props.error.code}
          message={this.props.error.message}
        />
      );
    }

    // console.log('renderContent(full) -> ', this.props.fullContent);

    return this.props.storeId && this.props.fullContent.length !== 0
      ? <LiveView /> : null;
  };

  render() {
    return (
      <MainContainer>
        {this.renderContent()}
      </MainContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetchingStore: storeSelectors.getFetching(state),
    error: storeSelectors.getErrorIfExist(state),
    fetchingLegend: legendSelectors.getFetching(state),
    storeId: storeSelectors.getStoreId(state),
    fullContent: storeSelectors.getFullStoreContent(state),
  };
}

export default connect(mapStateToProps)(withRouter(LiveViewContainer));
