import React, { Component } from 'react';

import { Box } from '@material-ui/core';

class MainContainer extends Component {

  render() {

    const { children } = this.props;

    const contentStyle = {
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    }

    return (
      <React.Fragment>
        <Box style={contentStyle}>
          { children }
        </Box>
      </React.Fragment>
    );

  }

}

export default MainContainer;
