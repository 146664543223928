/* eslint-disable no-console, no-param-reassign */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { MTLLoader, OBJLoader } from 'three-obj-mtl-loader';
import { Box } from '@material-ui/core';
import Stats from 'stats.js';

import config from '../../config';
import { MapControls } from '../../MapControls';
import Loader from '../Loader';
import Toolbar from '../Toolbar';
import normalizeAngle, { getCenterPositionByAngle } from '../../helpers/helper3d';
import { Store, StoreSize, StoreObject } from '../../model/Store';

import * as storeSelectors from '../../store/store/reducer';

const REALISM = 0.33;

const SKY_COLOR = 0xccf2ff;
const SKY_INTENSITY = 0.2 * REALISM;

const SUN_COLOR = 0xfff2ed;
const SUN_INTENSITY = 1.1 * REALISM;

const PRODUCT_COLOR = 0xcd9f61; // cardboard

const FLOOR_PLANE_COLOR = 0xAfafaf;
const FLOOR_TYPE_COLOR = '0';
const FLOOR_TYPE_IMAGE = '1';

const DEV_MODE = config.showFPS;

const CLICK_THRESHOLD = 5;
const TOUCH_THRESHOLD = 3;

const ENABLE_SHADOWS = false; // disbled, needs to be tuned

const BACK_WALLL_MAT = new THREE.MeshStandardMaterial({
  roughness: 0.5,
  metalness: 0.6,
  emissive: 0x0,
  color: 0xb8b8b8,
});

const USE_LOD = true;
const LOD_DISTANCE = 80;
const LOD_MATERIAL_PARAMS = {
  color: 0xD2D1CD,
  emissive: 0x070707,
  transparent: false,
  opacity: 0.5,
  roughness: 1,
  metalness: 0.1,
  clearcoat: 1,
  clearcoatRoughness: 0.5,
};

class LiveView extends Component {
  mouse = null;

  raycaster = null;

  renderer = null;

  scene = null;

  camera = null;

  INTERSECTED = null;

  currentObjectOnCast = null;

  selectObject = null;

  mouseDown = false;

  matrix = [];

  loadingManager = null;

  refStats = React.createRef();

  refScene = React.createRef();

  stats = new Stats();

  displayCount = 1;

  state = {
    isLoading: false,
    // loadingProgress: '-- / --',
  };

  componentDidMount() {
    if (DEV_MODE) {
      this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
      this.stats.dom.style.position = 'relative';
      this.refStats.current.appendChild(this.stats.dom);
    }

    console.time('did-mount-create3DView');
    this.create3DView();
    console.timeEnd('did-mount-create3DView');

    const canvas = document.getElementsByTagName('canvas')[0];
    canvas.addEventListener('mousedown', this.onDocumentMouseDown);
    canvas.addEventListener('mouseup', this.onDocumentMouseUp);
    canvas.addEventListener('mousemove', this.onDocumentMouseMove);
    canvas.addEventListener('click', this.onDocumentMouseClick);

    /* touch events */
    canvas.addEventListener('touchstart', this.onDocumentTouch);
    canvas.addEventListener('touchend', this.onDocumentTouchEnd);

    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillUnmount() {
    this.mouse = null;
    this.raycaster = null;
    this.renderer = null;
    this.scene = null;
    this.camera = null;
    this.loadingManager = null;
    if (this.mapControls) this.mapControls.enabled = false;
    this.mapControls = null;
    this.displayCount = 1;
    window.removeEventListener('resize', this.onWindowResize);
  }

  calcMaxDistance = (p1, p2) => Math.max(Math.abs(p1.x - p2.x), Math.abs(p1.y - p2.y));

  onDocumentMouseDown = (event) => {
    this.mouseDown = true;
    this.moveStartPosition = { x: event.clientX, y: event.clientY };
  };

  onDocumentMouseUp = () => {
    this.mouseDown = false;
  };

  onDocumentMouseMove = (event) => {
    event.preventDefault();
    this.mouse.x = ((event.clientX - this.renderer.domElement.offsetLeft)
      / this.renderer.domElement.width) * 2 - 1;
    this.mouse.y = -((event.clientY - this.renderer.domElement.offsetTop)
      / this.renderer.domElement.height) * 2 + 1;
  };

  onDocumentMouseClick = (event) => {
    this.moveEndPosition = { x: event.clientX, y: event.clientY };
    const diff = this.calcMaxDistance(this.moveStartPosition, this.moveEndPosition);
    if (diff > CLICK_THRESHOLD) return;

    if (this.INTERSECTED && this.selectObject !== this.INTERSECTED.uuid) {
      const self = this;
      this.getObjectsForIntersect().forEach((object) => {
        if (object.uuid !== self.INTERSECTED.uuid && object.firstColor) {
          object.material.color.setHex(String(object.firstColor));
        }
      });
      this.selectObject = this.INTERSECTED.uuid;
    }
  };

  onDocumentTouch = (event) => {
    event.preventDefault();
    const x = event.touches[0].clientX;
    const y = event.touches[0].clientY;
    this.mouse.x = ((x - this.renderer.domElement.offsetLeft)
      / this.renderer.domElement.width) * 2 - 1;
    this.mouse.y = -((y - this.renderer.domElement.offsetTop)
      / this.renderer.domElement.height) * 2 + 1;
    this.moveStartPosition = { x, y };
  };

  onDocumentTouchEnd = (event) => {
    const x = event.changedTouches[0].clientX;
    const y = event.changedTouches[0].clientY;

    this.mouse.x = ((x - this.renderer.domElement.offsetLeft)
      / this.renderer.domElement.width) * 2 - 1;
    this.mouse.y = -((y - this.renderer.domElement.offsetTop)
      / this.renderer.domElement.height) * 2 + 1;

    this.moveEndPosition = { x, y };

    const diff = this.calcMaxDistance(this.moveStartPosition, this.moveEndPosition);
    if (diff > TOUCH_THRESHOLD) return;

    this.onDocumentMouseClick(event);
  };

  create3DView = () => {
    console.time('canvas');
    this.createScene();
    console.timeEnd('canvas');
    console.time('animate');
    this.animate();
    console.timeEnd('animate');
    console.time('addPlane');
    this.addPlane();
    console.timeEnd('addPlane');
    console.time('createObjects');
    this.createObjects();
    console.timeEnd('createObjects');
    console.time('addLight');
    this.addLight();
    console.timeEnd('addLight');
  };

  animate = () => {
    if (DEV_MODE) this.stats.begin();

    if (this.renderer) {
      window.requestAnimationFrame(this.animate);

      this.renderer.render(this.scene, this.camera);
      this.mapControls.update();

      if (DEV_MODE) this.stats.end();
    }
  };

  getObjectsForIntersect = () => {
    const objects = [];
    this.scene.children.forEach((item) => {
      if (item.isShelf || item.isObject) {
        objects.push(item);
      }
    });
    return objects;
  };

  centeredStore = () => {
    const { storeSizes } = this.props;
    const { width, height } = storeSizes;
    this.camera.position.set(width / 2, width / 2, height * 4);
    this.mapControls.target.set(width / 2, 0, height / 2);
  };

  onManagerStart = (url, itemsLoaded, itemsTotal) => {
    this.setState({
      isLoading: true,
      progressItemsLoaded: itemsLoaded,
      progressItemsTotal: itemsTotal,
    });
  };

  onManagerLoad = () => {
    console.log('Loading complete!');
    this.setState({
      isLoading: false,
    });
  };

  onManagerProgress = (url, itemsLoaded, itemsTotal) => {
    this.setState({
      progressItemsLoaded: itemsLoaded,
      progressItemsTotal: itemsTotal,
    });
  };

  onManagerError = (url) => {
    console.log(`Error loading file: ${url}`);
  };

  createScene = () => {
    this.loadingManager = new THREE.LoadingManager(
      this.onManagerLoad,
      this.onManagerProgress,
      this.onManagerError
    );
    this.loadingManager.onStart = this.onManagerStart;

    this.mouse = new THREE.Vector2();
    this.raycaster = new THREE.Raycaster(), this.INTERSECTED; // eslint-disable-line
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setClearColor(0xa3a3a3, 1);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    this.renderer.setSize(
      this.refScene.current.clientWidth,
      this.refScene.current.clientHeight,
    );

    this.refScene.current.append(this.renderer.domElement);

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(SKY_COLOR);
    this.camera = new THREE.PerspectiveCamera(30, window.innerWidth / window.innerHeight, 1, 10000);
    this.mapControls = new MapControls(this.camera, this.renderer.domElement);
    this.centeredStore();
    this.mapControls.enableDamping = true;
    this.mapControls.dampingFactor = 1;
    this.mapControls.screenSpacePanning = false;
    this.mapControls.minDistance = 1;
    this.mapControls.maxDistance = 300;
    this.mapControls.maxPolarAngle = Math.PI / 2.1;
    this.mapControls.update();
  };

  addPlane = () => {
    const { storeSizes, store } = this.props;
    const { width, height } = storeSizes;
    const x = width / 2;
    const y = height / 2;
    const geometry = new THREE.PlaneGeometry(width, height, 0, 0);

    let optionsMaterial = null;
    if (store.floor_type) {
      if (store.floor_type === FLOOR_TYPE_COLOR && store.floor_color) {
        optionsMaterial = {
          color: store.floor_color,
          side: THREE.DoubleSide
        };
      } else if (store.floor_type === FLOOR_TYPE_IMAGE && store.floor_texture) {
        const texture = new THREE.TextureLoader(this.loadingManager).load(store.floor_texture);
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(width, height);
        optionsMaterial = {
          side: THREE.DoubleSide,
          map: texture,
        };
      }
    } else {
      optionsMaterial = {
        color: FLOOR_PLANE_COLOR,
        side: THREE.DoubleSide
      };
    }

    const material = new THREE.MeshPhongMaterial(optionsMaterial);
    const plane = new THREE.Mesh(geometry, material);

    if (ENABLE_SHADOWS) plane.receiveShadow = true;

    plane.isMainPlane = true;
    plane.rotateX(Math.PI / 2);
    plane.position.set(x, 0, y);

    this.scene.add(plane);
  };

  createObjects = () => {
    const { fullContent } = this.props;
    if (this.scene && fullContent && fullContent.length !== 0) {
      fullContent.forEach((object) => {
        if (object.type !== 'ruler' && object.type !== 'image') {
          if (object.nodeName === 'polygon' && object.type === 'wall') {
            this.createWalls(object);
          } else if (object.type === 'shelve') {
            this.createShelf(object).then(() => {
              this.createProducts(object);
            });
          }
        }
      });
    }
  };

  getWallForIntersect = () => {
    const objects = [];
    this.scene.children.forEach((item) => {
      if (item.isWall) {
        if (item.children && item.children.length !== 0) {
          item.children.forEach((childItem) => {
            if (childItem.children && childItem.children.length !== 0) {
              childItem.children.forEach((child) => {
                objects.push(child);
              });
            } else {
              objects.push(childItem);
            }
          });
        } else {
          objects.push(item);
        }
      }
    });
    return objects;
  };

  getDepthOffset = (object) => {
    this.scene.updateMatrixWorld();

    // console.log(object)
    let startPointCoords = {
      x: Number(object.x.replace(',', '.')) / 100 + object.products.shelfInfo.shelf_type.width / 100 / 2,
      y: object.products.shelfInfo.shelf_type.height / 100 / 2,
      z: Number(object.y.replace(',', '.')) / 100 + object.products.shelfInfo.shelf_type.length / 100
    };

    let direction = null;

    if (object.angle) {
      switch (object.angle) {
        case '0':
          direction = new THREE.Vector3(0, 0, -100).normalize();
          break;
        case '90':
          startPointCoords = {
            x: Number(object.x.replace(',', '.')) / 100,
            y: object.products.shelfInfo.shelf_type.height / 100 / 2,
            z: Number(object.y.replace(',', '.')) / 100 + object.products.shelfInfo.shelf_type.width / 100 / 2
          };
          direction = new THREE.Vector3(100, 0, 0).normalize();
          break;
        case '180':
          startPointCoords = {
            x: Number(object.x.replace(',', '.')) / 100 + object.products.shelfInfo.shelf_type.width / 100 / 2,
            y: object.products.shelfInfo.shelf_type.height / 100 / 2,
            z: Number(object.y.replace(',', '.')) / 100
          };
          direction = new THREE.Vector3(0, 0, 100).normalize();
          break;
        case '270':
          startPointCoords = {
            x: Number(object.x.replace(',', '.')) / 100 + object.products.shelfInfo.shelf_type.length / 100 * 1.5,
            y: object.products.shelfInfo.shelf_type.height / 100 / 2,
            z: Number(object.y.replace(',', '.')) / 100 + object.products.shelfInfo.shelf_type.width / 100 / 2
          };
          direction = new THREE.Vector3(-100, 0, 0).normalize();
          break;
        default:
          break;
      }
    }

    const startPoint = new THREE.Vector3(
      startPointCoords.x,
      startPointCoords.y,
      startPointCoords.z
    );
    const ray = new THREE.Raycaster(startPoint, direction);
    const intersects = ray.intersectObjects(this.getWallForIntersect());

    if (intersects && intersects.length !== 0) {
      return -intersects[0].distance;
    }
    return 0;
  };

  rotationByAngle = (angle) => {
    switch (angle) {
      case '90': return Math.PI * (3 / 2);
      case '180': return Math.PI;
      case '270': return Math.PI / 2;
      case '0': default: return 0;
    }
  };

  createProducts = (object) => {
    if (!this.scene) return;

    let shelfObj = null;
    this.scene.children.forEach((item) => {
      if (item.shelfId && item.shelfId === object.id) {
        shelfObj = item;
      }
    });

    if (shelfObj && object.angle) {
      shelfObj.rotation.y = this.rotationByAngle(object.angle);
    }

    if (object.products && object.products.products && object.products.products.length !== 0) {
      const mtlLoader = new THREE.MaterialLoader(this.loadingManager);
      const objLoader = new THREE.ObjectLoader(this.loadingManager);
      const texloader = new THREE.TextureLoader(this.loadingManager);

      const depthOffset = this.getDepthOffset(object);

      object.products.products.forEach((product) => {
        if (product.product.model) {
          mtlLoader.setPath(product.product.model.path);
          mtlLoader.load(product.product.model.mtl, (materials) => {
            materials.preload();
            objLoader.setMaterials(materials);
            objLoader.load(product.product.model.obj, (obj) => {
              obj.scale.set(0.01, 0.01, 0.01);
              if (obj.children) {
                obj.isParent = true;
                obj.children.forEach((child) => {
                  child.firstColor = child.material.color.getHex();
                  child.isChild = true;
                  child.isProduct = true;
                  child.productId = product.product.id;
                  child.productInfo = product;
                });
              }
              this.productAddToScene(shelfObj, obj, product, depthOffset, object);
            });
          });
        } else {
          const depth = product.product.depth && product.product.depth !== 'null'
            ? product.product.depth.replace(',', '.')
            : 10;
          const cubeGeometry = new THREE.BoxGeometry(
            Number(product.product.width.replace(',', '.') / 100),
            Number(product.product.height.replace(',', '.') / 100),
            Number(depth / 100)
          );
          let material = null;
          if (product.product.image) {
            const map = texloader.load(config.apiUrl + product.product.image);
            const mat = new THREE.MeshBasicMaterial({ map });
            material = [mat, mat, mat, mat, mat, mat];
          } else {
            material = new THREE.MeshStandardMaterial({
              roughness: 1,
              metalness: 0,
              color: PRODUCT_COLOR
            });
          }
          const cube = new THREE.Mesh(cubeGeometry, material);
          this.productAddToScene(shelfObj, cube, product, depthOffset, object);
        }
      });
    }
  };

  productAddToScene = (shelfObj, cube, product, depthOffset, object) => {
    if (ENABLE_SHADOWS) {
      cube.receiveShadow = true;
      cube.castShadow = true;
    }

    if (cube.material) {
      if (cube.material[0]) {
        cube.firstColor = cube.material[0].color.getHex();
      } else {
        cube.firstColor = cube.material.color.getHex();
      }
    }

    cube.isProduct = true;
    cube.productInfo = product;
    cube.productId = product.product.id;

    const depth = product.product.depth && product.product.depth !== 'null'
      ? product.product.depth.replace(',', '.')
      : 10;

    const x = Number(product.pos_x.replace(',', '.')) / 100
      + Number(product.product.width.replace(',', '.')) / 100 / 2
      - Number(object.products.shelfInfo.shelf_type.width) / 100 / 2;
    const y = shelfObj && shelfObj.isModel
      ? Number(product.pos_y.replace(',', '.')) / 100
        + Number(product.product.height.replace(',', '.') / 100 / 2)
      : Number(product.pos_y.replace(',', '.')) / 100
        + Number(product.product.height.replace(',', '.') / 100 / 2)
        - Number(object.products.shelfInfo.shelf_type.height) / 100 / 2;
    const z = shelfObj && shelfObj.isModel
      ? Number(depth / 100 / 2) + depthOffset
        + Number(object.products.shelfInfo.shelf_type.length) / 100 / 2
      : Number(depth / 100 / 2) + Number(object.products.shelfInfo.shelf_type.length) / 100 / 2;

    if (USE_LOD) {
      const lod = new THREE.LOD();

      lod.position.set(x, y, z);

      cube.position.set(0, 0, 0);
      lod.addLevel(cube, 0);

      const dotGeometry = new THREE.Geometry();
      dotGeometry.vertices.push(new THREE.Vector3(0, 0, 0));
      const dotMaterial = new THREE.PointsMaterial({
        color: PRODUCT_COLOR,
        size: 3,
        sizeAttenuation: false,
      });
      const dot = new THREE.Points(dotGeometry, dotMaterial);
      lod.addLevel(dot, LOD_DISTANCE / 2);

      const empty = new THREE.Object3D();
      lod.addLevel(empty, LOD_DISTANCE);

      if (shelfObj) shelfObj.add(lod);
      else this.scene.add(lod);
    } else {
      cube.position.set(x, y, z);
      if (shelfObj) shelfObj.add(cube);
      else this.scene.add(cube);
    }
  };

  createShelf = async (object) => {
    if (object.products && object.products.shelfInfo) {
      if (object.products.shelfInfo.shelf_type && object.products.shelfInfo.shelf_type.model_path) {
        await this.createShelfModel(object);
      } else {
        await this.createBackWall(object);
      }
    }
  };

  createShelfModel = async (object) => {
    const { shelf_type: shelfType } = object.products.shelfInfo;

    const shelfId = object.id;

    const [cx, cz] = getCenterPositionByAngle(object);
    const x = cx / 100;
    const y = cz / 100;

    await new Promise((resolve) => {
      const mtlLoader = new MTLLoader(this.loadingManager);
      const objLoader = new OBJLoader(this.loadingManager);

      const addObject = (object1) => {
        object1.scale.set(0.01, 0.01, 0.01);

        const obj = new THREE.Object3D();
        obj.add(object1);

        obj.shelfId = shelfId;
        obj.isModel = true;
        obj.isWall = true;

        if (ENABLE_SHADOWS) obj.castShadow = true;

        if (USE_LOD) {
          const lod = new THREE.LOD();

          lod.position.set(x, 0, y);
          lod.rotation.y = this.rotationByAngle(object.angle);

          obj.position.set(0, 0, 0);
          lod.addLevel(obj, 0);

          const width = Number(shelfType.width) / 100;
          const height = Number(shelfType.height) / 100;
          const length = Number(shelfType.length) / 100;

          const geometry = new THREE.CubeGeometry(width, height, length);

          const material = new THREE.MeshPhysicalMaterial(LOD_MATERIAL_PARAMS);
          if (object.fill) material.color = new THREE.Color(object.fill);

          const cube = new THREE.Mesh(geometry, material);
          if (ENABLE_SHADOWS) {
            cube.receiveShadow = true;
            cube.castShadow = true;
          }

          cube.position.set(0, height / 2, 0);
          lod.addLevel(cube, LOD_DISTANCE);

          this.scene.add(lod);
        } else {
          obj.position.set(x, 0, y);
          this.scene.add(obj);
        }

        resolve();
      };

      const addMaterials = (materials) => {
        materials.preload();

        objLoader.setMaterials(materials);
        objLoader.load(config.apiUrl + shelfType.model_path, addObject);
      };

      if (shelfType.material_path) {
        mtlLoader.load(config.apiUrl + shelfType.material_path, addMaterials);
      } else objLoader.load(config.apiUrl + shelfType.model_path, addObject);
    });
  };

  createBackWall = (object) => {
    const shelfInfo = object.products.shelfInfo;

    const geometry = new THREE.CubeGeometry(
      Number(shelfInfo.shelf_type.width) / 100,
      Number(shelfInfo.shelf_type.height) / 100,
      Number(shelfInfo.shelf_type.length) / 100
    );
    const cube = new THREE.Mesh(geometry, BACK_WALLL_MAT);

    cube.isWall = true;
    cube.shelfId = object.id;

    const [cx, cz] = getCenterPositionByAngle(object);
    const x = cx / 100;
    const y = cz / 100;

    cube.position.set(x, Number(shelfInfo.shelf_type.height) / 100 / 2, y);

    if (ENABLE_SHADOWS) cube.castShadow = true;

    this.scene.add(cube);
  };

  createWalls = (object) => {
    const pointsArray = object.points.split(' ').map(item => item.split(','));

    for (let i = 0; i < pointsArray.length; i += 1) {
      const startWallPoint = {
        x: Number(pointsArray[i][0]),
        y: Number(pointsArray[i][1]),
      };
      let endWallPoint = null;
      if (pointsArray[i + 1]) {
        endWallPoint = {
          x: Number(pointsArray[i + 1][0]),
          y: Number(pointsArray[i + 1][1]),
        };
      } else {
        endWallPoint = {
          x: Number(pointsArray[0][0]),
          y: Number(pointsArray[0][1]),
        };
      }
      this.createWall(startWallPoint, endWallPoint, object);
    }
  };

  createWall = (startWallPoint, endWallPoint, object) => {
    const XLineLength = Math.abs(startWallPoint.x - endWallPoint.x);
    const YLineLength = Math.abs(startWallPoint.y - endWallPoint.y);
    const wallLength = Math.sqrt((XLineLength ** 2) + (YLineLength ** 2));

    const angle = normalizeAngle(startWallPoint, endWallPoint, Math.asin(YLineLength / wallLength));

    const geometry = new THREE.CubeGeometry(
      object.strokeWidth / 100,
      4,
      wallLength / 100 + (object.strokeWidth / 100)
    );

    const material = new THREE.MeshPhongMaterial({
      color: new THREE.Color(object.stroke),
      shininess: 20,
      specular: 0xebebeb,
      reflectivity: 1,
    });

    const cube = new THREE.Mesh(geometry, material);

    if (ENABLE_SHADOWS){
      cube.receiveShadow = true;
      cube.castShadow = true;
    }

    cube.isDraggable = true;

    const midPoint = this.getMidPointOfWall(startWallPoint, endWallPoint);

    cube.position.set(midPoint.x / 100, 2, midPoint.y / 100);

    cube.rotateY(angle);

    this.scene.add(cube);
  };

  getMidPointOfWall = (startWallPoint, endWallPoint) => ({
    x: (startWallPoint.x + endWallPoint.x) / 2,
    y: (startWallPoint.y + endWallPoint.y) / 2,
  });

  addLight = () => {
    const { storeSizes } = this.props;
    const { width: w, height: h } = storeSizes;

    const whiteIntensity = 1 - (SKY_INTENSITY + SUN_INTENSITY);
    const whiteLight = new THREE.AmbientLight(0xFFFFFF, whiteIntensity);
    this.scene.add(whiteLight);

    const skyLight = new THREE.AmbientLight(SKY_COLOR, SKY_INTENSITY);
    this.scene.add(skyLight);

    const sunLight = new THREE.DirectionalLight(SUN_COLOR, SUN_INTENSITY);

    if (ENABLE_SHADOWS) {
      sunLight.castShadow = true;
      sunLight.shadow.camera.near = 2;
      sunLight.shadow.camera.far = 100;
    }

    sunLight.position.set(w / 2, 10, h / 2);
    this.scene.add(sunLight);

    const offsetX = Math.random() * 2;
    const offsetZ = Math.random() * 2;

    sunLight.target.position.set(w / 2 + offsetX, 1, h / 2 + offsetZ);
    this.scene.add(sunLight.target);
  };

  onWindowResize = () => {
    if (!this.renderer || !this.camera) return;

    const width = this.refScene.current.clientWidth;
    const height = this.refScene.current.clientHeight;

    this.renderer.setSize(width, height);

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  };

  render() {
    const {
      isLoading,
      progressItemsLoaded,
      progressItemsTotal,
    } = this.state;

    return (
      <Box
        id="3d-view-container"
        key="lv-scene"
        ref={this.refScene}
        overflow="hidden"
        backgroundcolor="#eeeeee"
        height="100vh"
        width="100vw"
        position="relative"
      >
        <Loader
          isActive={isLoading}
          progress={33}
          title="Loading models and textures..."
          subTitle={`${progressItemsLoaded}/${progressItemsTotal}`}
        />
        <Toolbar showGoBack />
        { DEV_MODE
          && (
          <Box
            id="stats"
            key="stats"
            ref={this.refStats}
            position="absolute"
            bottom={30}
            left={30}
            style={isLoading ? { display: 'none' } : {}}
          />
          )
        }
      </Box>
    );
  }
}

LiveView.propTypes = {
  store: PropTypes.shape(Store),
  storeSizes: PropTypes.shape(StoreSize),
  fullContent: PropTypes.arrayOf(PropTypes.shape(StoreObject))
};

LiveView.defaultProps = {
  store: null,
  storeSizes: null,
  fullContent: null
};

function mapStateToProps(state) {
  return {
    storeContent: storeSelectors.getStoreContent(state),
    storeSizes: storeSelectors.getStoreSizes(state),
    store: storeSelectors.getStore(state),
    storeId: storeSelectors.getStoreId(state),
    fullContent: storeSelectors.getFullStoreContent(state),
  };
}

export default connect(mapStateToProps)(withRouter(LiveView));
