import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  products: [],
  /*
  products: [
    {
      product_number: '222',
      ... other,
      quantity: 5,
    }
  ],
  */
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.ADD_PRODUCT:
      return state.merge({
        products: [
          ...state.products,
          {
            ...action.product,
            quantity: action.quantity,
          },
        ],
      });
    case types.ADD_QUANTITY_TO_PRODUCT:
    {
      const products = state.products.map((product) => {
        if (product.id === action.productId) {
          const newQuantity = Number(product.quantity) + Number(action.quantity);
          return {
            ...product,
            quantity: newQuantity < 0 ? 0 : newQuantity,
          };
        }
        return product;
      });
      return state.merge({
        products: products.filter(product => product.quantity > 0),
      });
    }
    case types.REMOVE_PRODUCT:
      return state.merge({
        products: state.products.filter(product => product.id !== action.productId),
      });
    default:
      return state;
  }
}

export function getProductsFromBasket(state) {
  return state.shoppingbasket.products;
}
