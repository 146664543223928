import React, { Component } from 'react';
import {
  Button,
  Divider,
} from '@material-ui/core';

class DisplaySection extends Component {
  
  componentDidMount() {
    if (this.props.displaysList.length > 0) {
      this.props.showCurrentDisplayOnMap();
    }
  }
  
  render() {
    return (
      <div>
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          Display select
        </div>
        <Divider />
        { this.renderDisplaySelect() }
      </div>
    );
  }
  
  renderDisplaySelect = () => {
    if (this.props.displaysList.length > 0) {
      return (
        <div style={{ padding: 20 }}>
          <div style={{ textAlign: 'center' }}>
            Current display: { this.props.currentDisplayId }
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{ margin: 3 }}
              variant="outlined" color="secondary"
              onClick={() => this.changeDisplay('prev')}
            >
              {'<'}
            </Button>
            <Button
              style={{ margin: 3 }}
              variant="outlined" color="secondary"
              onClick={() => this.changeDisplay('next')}
            >
              {'>'}
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div style={{ textAlign: 'center' }}>Displays not found</div>
    );
  };
  
  changeDisplay = type => {
    let newId = null;
    if (type === 'next') {
      if (this.props.currentDisplayId < this.props.displaysList.length) {
        newId = this.props.currentDisplayId + 1;
      } else {
        newId = 1;
      }
    } else {
      if (this.props.currentDisplayId !== 1) {
        newId = this.props.currentDisplayId - 1;
      } else {
        newId = this.props.displaysList.length;
      }
    }
    this.props.changeDisplay(newId);
  }
}

export default DisplaySection;
