import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  fetching: false,
  legends: [],
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOAD_LEGEND:
      return state.merge({
        fetching: false,
        legends: action.data.map((colorGroup) => {
          return {
            ...colorGroup,
          };
        }),
      });
    case types.SET_FETCHING:
      return state.merge({
        fetching: true,
      });
    default:
      return state;
  }
}

export function getFetching(state) {
  return state.legend.fetching;
}

export function getLegend(state) {
  return state.legend.legends;
}
