import React from 'react';
import { useTheme } from '@material-ui/styles';
// import { fade } from '@material-ui/core/styles';
import {
  Box, CircularProgress, LinearProgress, Typography,
} from '@material-ui/core';


export default ({
  isActive = false, progress = 0, total = 100, title = 'Loading...', subTitle = '',
}) => {
  const theme = useTheme();
  const value = Math.round((progress * 100) / total);
  // const fadedColor = fade(theme.palette.background.default, 0.9);
  const fadedColor = theme.palette.background.paper;
  return isActive
    && (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        left={0}
        top={0}
        right={0}
        bottom={0}
        zIndex={theme.zIndex.appBar + 10}
        style={{ backgroundColor: fadedColor }}
      >
        <LinearProgress
          style={{
            position: 'absolute', left: 0, top: 0, width: '100%',
          }}
          color="secondary"
        />
        <img src="/images/load-banner.jpg" alt="EasyBoard" />
        <Typography variant="h5" color="textPrimary" gutterBottom>
          {title}
        </Typography>

        <CircularProgress value={value} style={{ margin: theme.spacing(2) }} />

        {subTitle
        && (
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {subTitle}
          </Typography>
        )
        }
      </Box>
    );
};
