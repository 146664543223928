import * as types from './actionTypes';

export function addProduct(product, quantity) {
  return (dispatch, getState) => {
    dispatch({
      type: types.ADD_PRODUCT,
      product,
      quantity,
    });
  };
}

export function addQuantityToProduct(productId, quantity) {
  return (dispatch, getState) => {
    dispatch({
      type: types.ADD_QUANTITY_TO_PRODUCT,
      productId,
      quantity,
    });
  };
}

export function removeProduct(productId) {
  return (dispatch, getState) => {
    dispatch({
      type: types.REMOVE_PRODUCT,
      productId,
    });
  };
}
