import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

import { withTheme } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { Delete, Category, Close } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { connect } from 'react-redux';

import * as basketSelectors from '../../store/shoppingbasket/reducer';
import * as basketActions from '../../store/shoppingbasket/actions';

class BasketDialog extends React.PureComponent {
  removeFromCartClick = (productId) => {
    const { dispatch } = this.props;
    dispatch(
      basketActions.removeProduct(productId)
    );
  };

  addQuantityToProduct = (productId, quantity) => {
    const { dispatch } = this.props;
    dispatch(
      basketActions.addQuantityToProduct(productId, quantity)
    );
  };

  renderList = () => {
    const { theme, productsInBasket } = this.props;

    return (
      <List>
        {
          productsInBasket.map(product => (
            <ListItem key={product.id}>
              <ListItemAvatar>
                {
                  product.image
                    ? <Avatar src={product.image} />
                    : (
                      <Avatar>
                        <Category />
                      </Avatar>
                    )
                }
              </ListItemAvatar>
              <ListItemText
                primary={product.description1_de}
                secondary={(
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <IconButton
                      edge="start"
                      size="small"
                      onClick={() => { this.addQuantityToProduct(product.id, -1); }}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <TextField
                      margin="dense"
                      label="Quantity"
                      value={product.quantity}
                    />
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={() => { this.addQuantityToProduct(product.id, 1); }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                )}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={() => {
                    this.removeFromCartClick(product.id);
                  }}
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        }
      </List>
    );
  };

  render() {
    const {
      theme,
      close,
      isOpen,
      productsInBasket
    } = this.props;
    const emptyBasketStyles = {
      paddingBottom: theme.spacing(2)
    };
    return (
      <Dialog onClose={close} open={isOpen}>
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>Shopping cart</span>
            <IconButton edge="end" onClick={close}>
              <Close color="primary" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          { productsInBasket.length !== 0
            ? this.renderList()
            : <div style={emptyBasketStyles}>Cart is empty</div>
          }
        </DialogContent>
      </Dialog>
    );
  }
}

export class BasketItem {
  quantity;
}

BasketDialog.propTypes = {
  theme: PropTypes.shape(Theme).isRequired,
  dispatch: PropTypes.func.isRequired,
  productsInBasket: PropTypes.arrayOf(PropTypes.shape(BasketItem)).isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

BasketDialog.defaultProps = { };

function mapStateToProps(state) {
  return {
    productsInBasket: basketSelectors.getProductsFromBasket(state),
  };
}

export default connect(mapStateToProps)(withTheme(BasketDialog));
