import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

const initialState = Immutable({
  fetching: false,
  shelfInfo: {
    width: null,
    height: null,
    depth: null,
  },
  products: [],
  error: {
    status: false,
    code: null,
    message: null,
  },
  findSection: {
    productName: null,
    shelvesIds: [],
  },
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_FETCHING:
      return state.merge({
        fetching: action.isFetching,
      });
    case types.LOAD_PRODUCTS:
      return state.merge({
        shelfInfo: {
          width: Number(action.data.shelfInfo.shelf_type.width),
          height: Number(action.data.shelfInfo.shelf_type.height),
          depth: Number(action.data.shelfInfo.shelf_type.length),
          ...action.data.shelfInfo,
        },
        products: action.data.products,
        neighbours: action.data.neighbours.map(n => ({
          ...n,
          width: n.shelf_type ? n.shelf_type.width : undefined,
          height: n.shelf_type ? n.shelf_type.height : undefined,
          length: n.shelf_type ? n.shelf_type.length : undefined,
        })),
      });
    case types.LOAD_ERROR:
      return state.merge({
        error: {
          status: true,
          code: action.code,
          message: action.errorMessage,
        },
      });
    case types.REMOVE_PRODUCTS:
      return state.merge({
        shelfInfo: {
          width: null,
          height: null,
          depth: null,
        },
        products: [],
      });
    case types.SET_FINDING:
      return state.merge({
        findSection: {
          productName: action.productName,
          shelvesIds: action.shelvesIds,
        },
      });
    default:
      return state;
  }
}

export function getFinding(state) {
  return state.shelf.findSection;
}

export function getErrorIfExist(state) {
  return state.shelf.error.status
    ? state.shelf.error
    : null;
}

export function getShelfInfo(state) {
  if (state.shelf.shelfInfo.width
   && state.shelf.shelfInfo.height
   && state.shelf.shelfInfo.depth) {
    return state.shelf.shelfInfo;
  }
  return null;
}

export function getNeighbours(state) {
  return state.shelf.neighbours;
}

export function getFetching(state) {
  return state.shelf.fetching;
}

export function getStore(state) {
  return state.shelf;
}

export function getStoreId(state) {
  return state.shelf.storeId;
}

export function getProducts(state) {
  if (state.shelf.products
   && state.shelf.products.length
   && state.shelf.products.length !== 0) {
    return state.shelf.products;
  }
  return [];
}
