import React, { Component } from 'react';
import { Route } from 'react-router';
import { Switch, BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import './App.css';
import StoreContainer from './containers/StoreContainer';
import LiveViewContainer from './containers/LiveViewContainer';
import theme from './theme';
import ShelfView3D from './components/ShelfView3D/ShelfView3D';

class App extends Component {
  componentDidMount() {
    if (window.location.pathname === '/') {
      window.location.href = '/admin';
    }
  }

  render() {
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <BrowserRouter forceRefresh>
            <Switch>
              <Route path="/:storeId/live-view" component={LiveViewContainer} />
              <Route path="/:storeId/:currentDisplayId?" exact component={StoreContainer} />
              <Route path="/:storeId/shelf-view/:shelfId" component={ShelfView3D} />
            </Switch>
          </BrowserRouter>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;
