import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Fab,
  Icon
} from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import Tween from '@tweenjs/tween.js';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary.contrastText,
  }
}));

const Zoomer = ({
  camera, update, duration, initialPosition
}) => {
  const STATES = {
    regular: 0,
    highlighted: 1,
    zoomed: 2,
  };
  const classes = useStyles();
  const [sceneState, setSceneState] = useState(STATES.regular);
  let tween = null;
  let animationFinished = false;
  const animate = () => {
    if (tween) {
      tween.update(Tween.now());
    }
    if (!animationFinished) {
      window.requestAnimationFrame(animate);
    }
  };
  const getIconsParamsForState = (state) => {
    const params = {
      className: classes.icon,
      name: 'zoom_in',
    };
    switch (state) {
      case STATES.zoomed: {
        params.name = 'reply';
        break;
      }
      case STATES.highlighted: {
        params.name = 'reply';
        break;
      }
      case STATES.regular:
      default: {
        break;
      }
    }
    return params;
  };
  const goTo = (goOut) => {
    const toPosition = goOut ? { x: 20, y: 20, z: 20 } : initialPosition;
    const position = camera.position.clone();
    tween = new Tween.Tween({ x: Number(position.x), y: Number(position.y) })
      .to(toPosition, duration);
    tween.onUpdate((pos) => {
      camera.position.set(pos.x, pos.y, camera.position.z);
      update();
    })
      .onComplete(() => {
        animationFinished = true;
        tween.stop();
      })
      .start();
    animate();
  };
  const changeSceneState = () => {
    let operand = 1;
    switch (sceneState) {
      case STATES.zoomed:
      case STATES.highlighted: {
        goTo(false);
        operand = -1;
        break;
      }
      case STATES.regular:
      default: {
        goTo(true);
        break;
      }
    }
    setSceneState(sceneState + operand);
  };
  const onClickHandler = () => {
    changeSceneState();
  };
  const iconParams = getIconsParamsForState(sceneState);
  return (
    <Fab
      disabled={!camera}
      id="zoomer-fab"
      color="primary"
      className={classes.fab}
      onClick={onClickHandler}
    >
      <Icon className={iconParams.className}>{iconParams.name}</Icon>
    </Fab>
  );
};

Zoomer.propTypes = {
  camera: PropTypes.shape(),
  initialPosition: PropTypes.shape(),
  update: PropTypes.func.isRequired,
  duration: PropTypes.number,
};
Zoomer.defaultProps = {
  camera: null,
  duration: 500,
  initialPosition: null,
};

export default Zoomer;
