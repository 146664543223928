import axios from 'axios';
import * as types from './actionTypes';
import config from '../../config';

export function loadStore(id, short = false) {
  return (dispatch, getState) => axios({
    method: 'GET',
    url: `${config.apiUrl}/api/get-store-by-id`,
    params: {
      id,
      'without-map-content': short,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.data) {
        dispatch({ type: types.LOAD_STORE, data: response.data });
      }
    })
    .catch((error) => {
      if (error.response.status === 404 && error.response.data.message) {
        dispatch({
          type: types.LOAD_STORE_ERROR,
          code: error.response.status,
          errorMessage: error.response.data.message,
        });
      } else {
        dispatch({
          type: types.LOAD_STORE_ERROR,
          code: error.response.status,
          errorMessage: 'Unexpected error',
        });
      }
    });
}

export function loadFullStoreContent(id) {
  return (dispatch, getState) => axios({
    method: 'GET',
    url: `${config.apiUrl}/api/get-full-store-content`,
    params: {
      id,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.data) {
        dispatch({ type: types.LOAD_FULL_CONTENT, data: response.data });
      }
    })
    .catch((error) => {
      if (error.response.status === 404 && error.response.data.message) {
        dispatch({
          type: types.LOAD_STORE_ERROR,
          code: error.response.status,
          errorMessage: error.response.data.message,
        });
      } else {
        dispatch({
          type: types.LOAD_STORE_ERROR,
          code: error.response.status,
          errorMessage: 'Unexpected error',
        });
      }
    });
}


export function setFetching(isFetching) {
  return (dispatch, getState) => {
    dispatch({ type: types.SET_FETCHING, isFetching });
  };
}

export function searchProducts(productsName, storeId) {
  if (productsName !== '' && productsName.length > 1) {
    return axios({
      method: 'GET',
      url: `${config.apiUrl}/api/find-product`,
      params: { subStoreId: storeId, product: productsName },
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        if (response.data) {
          return response.data.map(item => ({ label: item.description1_de, ...item }));
        }
        return [];
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }
  return new Promise(resolve => resolve([]));
}
